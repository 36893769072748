<admin-app-header></admin-app-header>
<div class="container-fluid">
    <ng-container *ngIf="subscription">
        <br />
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="content-box">
                        <h1>Subscription Details</h1>
                        <div>
                            Status: <span [ngClass]="subscription.statusBadgeColour" class="badge">
                            {{ subscription.statusNiceName }}
                        </span>
                        </div>

                        <div>Key: {{ subscription.$key }}</div>
                        <div *ngIf="subscription.$client">
                            Requested by:
                            <a [routerLink]="['/client/', subscription.$client.$key]">
                                {{ subscription.$client.firstName + ' ' + subscription.$client.lastName }}
                                <span *ngIf="!subscription.$client.firstName" class="undefined">Undefined</span>
                            </a>
                        </div>
                        <div>Bays Booked: {{ subscription.baysBooked }}</div>
                        <div>Start Date: {{ subscription.startDate | formatDate }}</div>
                    </div>
                </div>

                <div class="col-md-12 col-sm-12 col-xs-12 mt-5">
                    <h3>Occupants / Vehicles</h3>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="row">
                        <div *ngFor="let occupant of subscription.$occupants" class="col-md-4">
                            <div class="card p-3">
                                <i class="fa-solid fa-car floating-icon"></i>
                                <div>Full Name: {{ occupant.fullName }}</div>
                                <div>Model: {{ occupant.model }}</div>
                                <div>Colour: {{ occupant.colour }}</div>
                                <div>Registration: {{ occupant.registration }}</div>
                            </div>
                        </div>
                        <!--    <qrcode [qrdata]="qrData" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>-->

                        <!--                    <section class="main-timeline-section">-->
                        <!--                        <div class="timeline-start"></div>-->
                        <!--                        <div class="conference-center-line"></div>-->
                        <!--                        <div class="conference-timeline-content">-->
                        <!--                            <div class="timeline-article content-left-container">-->
                        <!--                                <div class="content-date">-->
                        <!--                            <span title="{{subscription.createdAt.toDate() | date:'dd LLL YYYY h:mm:ss a'}}">-->
                        <!--                                {{subscription.createdAt.toDate() | date:'dd LLL YYYY'}}-->
                        <!--                            </span>-->
                        <!--                                </div>-->
                        <!--                                <div class="meta-date"></div>-->
                        <!--                                <div class="content-box">-->
                        <!--                                    <h5>Subscription Created</h5>-->
                        <!--                                    <div>Key: {{subscription.$key}}</div>-->
                        <!--                                    <div *ngIf="subscription.$client">-->
                        <!--                                        Requested by:-->
                        <!--                                        <a [routerLink]="['/client/', subscription.$client.$key]">-->
                        <!--                                            {{subscription.$client.firstName + ' ' + subscription.$client.lastName}}-->
                        <!--                                            <span *ngIf="!subscription.$client.firstName" class="undefined">Undefined</span>-->
                        <!--                                        </a>-->
                        <!--                                    </div>-->
                        <!--                                    <div>Bays Booked: {{subscription.baysBooked}}</div>-->
                        <!--                                    <div>Start Date: {{subscription.startDate | formatDate}}</div>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="timeline-end"></div>-->
                        <!--                    </section>-->
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
