import { Injectable } from '@angular/core'
import {
    addDoc,
    collection,
    collectionData,
    doc,
    docData,
    DocumentData,
    DocumentReference,
    Firestore,
    getDocs,
    limit,
    orderBy,
    query,
    QueryDocumentSnapshot,
    serverTimestamp,
    startAfter,
    updateDoc,
    where,
} from '@angular/fire/firestore'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { AuthenticationService } from './authentication.service'
import { from, map, Observable, of, switchMap } from 'rxjs'
import { Client, FirestoreStructure, Invoice, InvoiceStatus } from '@parkupp/core'
import { ClientService } from './client.service'
import { MerchantService } from './merchant.service'
import { ParkingService } from './parking.service'
import { SubscriptionService } from './subscription.service'
import * as firestore from 'firebase/firestore'

@Injectable({
    providedIn: 'root',
})
export class InvoiceService {
    collection = collection(this.firestore, FirestoreStructure.INVOICES)

    constructor(
        private authSvc: AuthenticationService,
        public firestore: Firestore,
        private router: Router,
        private http: HttpClient,
        private clientService: ClientService,
        private merchantService: MerchantService,
        private parkingService: ParkingService,
        private subscriptionService: SubscriptionService
    ) {}

    getDocRef(invoiceKey: string) {
        return doc(this.firestore, `${FirestoreStructure.INVOICES}/${invoiceKey}`)
    }

    async create(invoice: Invoice) {
        if (!invoice.dueDate) {
            invoice.dueDate = serverTimestamp() as firestore.Timestamp
        }

        return addDoc(this.collection, invoice.parse()).then((docRef) => {
            return { status: 'success', reference: docRef.id }
        })
    }

    async update(invoice: Invoice): Promise<void> {
        const invoiceRef = doc(this.collection, invoice.$key)
        await updateDoc(invoiceRef, invoice.parse())
    }

    list(startAfterDoc?: QueryDocumentSnapshot<Invoice>, pageSize: number = 200, status?: InvoiceStatus): Observable<QueryDocumentSnapshot<DocumentData>[]> {
        let invoicesQuery = query(this.collection, orderBy('createdAt', 'desc'), limit(pageSize))

        if (startAfterDoc) {
            invoicesQuery = query(invoicesQuery, startAfter(startAfterDoc))
        }

        if (status) {
            invoicesQuery = query(invoicesQuery, where('status', '==', status))
        }

        return from(getDocs(invoicesQuery)).pipe(
            map((snapshot) => snapshot.docs) // Map from QuerySnapshot to an array of QueryDocumentSnapshots
        )
    }

    listNoLimit(fromDate?: Date, toDate?: Date): Observable<QueryDocumentSnapshot<DocumentData>[]> {
        let invoicesQuery = query(this.collection, orderBy('createdAt', 'desc'))

        if (fromDate) {
            invoicesQuery = query(invoicesQuery, where('createdAt', '>=', fromDate))
        }

        if (toDate) {
            invoicesQuery = query(invoicesQuery, where('createdAt', '<=', toDate))
        }

        if (status) {
            invoicesQuery = query(invoicesQuery, where('status', '==', status))
        }

        return from(getDocs(invoicesQuery)).pipe(
            map((snapshot) => snapshot.docs) // Map from QuerySnapshot to an array of QueryDocumentSnapshots
        )
    }

    listOverdueInvoices(): Observable<Invoice[]> {
        const sevenDaysAgo = new Date()
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5)
        const overdueInvoicesQuery = query(this.collection, where('status', '==', InvoiceStatus.DUE), where('dueDate', '<', sevenDaysAgo))
        return collectionData(overdueInvoicesQuery, { idField: '$key' }).pipe(map((invoices: any[]) => invoices.map((invoiceData) => new Invoice(invoiceData)))) as Observable<Invoice[]>
    }

    listInvoicesNotPaidOut(): Observable<Invoice[]> {
        const overdueInvoicesQuery = query(this.collection, where('status', '==', InvoiceStatus.PAID), where('paidOut', '==', false))
        return collectionData(overdueInvoicesQuery, { idField: '$key' }).pipe(map((invoices: any[]) => invoices.map((invoiceData) => new Invoice(invoiceData)))) as Observable<Invoice[]>
    }

    get(invoiceKey: string, fields: { client?: boolean; merchant?: boolean; parking?: boolean; subscription?: boolean } = {}): Observable<any> {
        const invoiceDocRef = doc(this.firestore, `${FirestoreStructure.INVOICES}/${invoiceKey}`)

        return docData(invoiceDocRef, { idField: '$key' }).pipe(
            switchMap((invoice: any) => {
                // Start with an observable of the invoice data
                let data$ = of(invoice)

                // If 'client' field is requested and exists, chain the observable to add client data
                if (fields.client && invoice.clientRef) {
                    data$ = data$.pipe(switchMap((invoiceData) => this.clientService.get(invoice.clientRef.id).pipe(map((clientData) => ({ ...invoiceData, $client: clientData })))))
                }

                // Repeat for merchant
                if (fields.merchant && invoice.merchantRef) {
                    data$ = data$.pipe(switchMap((invoiceData) => this.merchantService.get(invoice.merchantRef.id).pipe(map((merchantData) => ({ ...invoiceData, $merchant: merchantData })))))
                }

                // Repeat for parking
                if (fields.parking && invoice.parkingRef) {
                    data$ = data$.pipe(switchMap((invoiceData) => this.parkingService.get(invoice.parkingRef.id).pipe(map((parkingData) => ({ ...invoiceData, $parking: parkingData })))))
                }

                // Repeat for subscription
                if (fields.subscription && invoice.subscriptionRef) {
                    data$ = data$.pipe(
                        switchMap((invoiceData) => this.subscriptionService.get(invoice.subscriptionRef.id).pipe(map((subscriptionData) => ({ ...invoiceData, $subscription: subscriptionData }))))
                    )
                }

                // Return the final observable
                return data$
            })
        )
    }
    async getInvoicesByPayout(payoutRef: string): Promise<Invoice[]> {
        const invoicesQuery = query(this.collection, where('payoutRef', '==', payoutRef))
        const snapshot = await getDocs(invoicesQuery)
        return snapshot.docs.map((doc) => new Invoice({ ...doc.data(), $key: doc.id }))
    }

    getInvoicesByClientRef(clientRef: string): Observable<Invoice[]> {
        // Adjust the 'createdAt' field name if your documents use a different field for sorting
        const invoicesQuery = query(
            this.collection,
            where('clientRef', '==', clientRef),
            orderBy('createdAt', 'desc') // Add this line, replace 'createdAt' with your field name
        )
        return collectionData(invoicesQuery, { idField: '$key' }).pipe(map((invoices: any[]) => invoices.map((invoiceData) => new Invoice(invoiceData)))) as Observable<Invoice[]>
    }

    getStatusClass(invoice: Invoice) {
        if (invoice.status === InvoiceStatus.PAID) {
            return 'bg-success'
        }
        if (invoice.status === InvoiceStatus.CANCELLED) {
            return 'bg-danger'
        }
        if (invoice.status === InvoiceStatus.REFUNDED) {
            return 'bg-danger'
        }
        return 'bg-warning'
    }

    async cancelInvoice(invoice: Invoice) {
        invoice.status = InvoiceStatus.CANCELLED
        console.log(invoice)
        return await this.update(invoice)
    }

    async markAsPaid(invoice: Invoice) {
        invoice.status = InvoiceStatus.PAID
        invoice.paymentDate = serverTimestamp() as firestore.Timestamp

        await this.update(invoice)
    }

    async markAsClientBreeched(invoice: Invoice) {
        invoice.status = InvoiceStatus.BREECHED
        await this.update(invoice)
    }

    async markAsRefunded(invoice: Invoice) {
        invoice.status = InvoiceStatus.REFUNDED
        await this.update(invoice)
    }

    async markAsDue(invoice: Invoice) {
        invoice.status = InvoiceStatus.DUE
        await this.update(invoice)
    }

    async markAsCancelled(invoice: Invoice) {
        invoice.status = InvoiceStatus.CANCELLED
        await this.update(invoice)
    }
}
