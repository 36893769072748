<admin-app-header></admin-app-header>
<div class="container-fluid">
    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm">
            <thead>
                <tr>
                    <th scope="col">Updated</th>
                    <th scope="col">Status</th>
                    <th scope="col">Name</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Type</th>
                    <th scope="col">Bays</th>
                    <th scope="col">Bank</th>
                    <th scope="col">Address</th>
                    <th scope="col">Service Agreement</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="parkings">
                    <ng-container *ngFor="let parking of parkings">
                        <tr (contextmenu)="showContextMenu($event, parking)" [ngClass]="{ selected: selectedParking === parking }">
                            <td>
                                {{ parking.updatedAt | daysAgo }}
                            </td>
                            <td>
                                <a>
                                    <span *ngIf="parking.parkingStatus == parkingStatus.DISABLED" class="badge bg-danger text-dark">Disabled</span>
                                    <span *ngIf="parking.parkingStatus == parkingStatus.PUBLISHED" class="badge bg-success text-dark">Published</span>
                                    <span *ngIf="parking.parkingStatus == parkingStatus.NEW" class="badge bg-warning text-dark">Draft</span>
                                    <span *ngIf="parking.parkingStatus == parkingStatus.PENDING" class="badge bg-warning text-dark">Pending</span>
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/merchant', parking.merchantRef?.id, 'parking', parking.$key]">
                                    <i class="fa-solid fa-pencil"></i>
                                </a>
                                <a [routerLink]="['/parking', parking.$key]">
                                    {{ parking.title || '' }}
                                    <span *ngIf="!parking.title" class="undefined">Undefined</span>
                                </a>
                            </td>
                            <td>
                                <a *ngIf="parking.merchantRef" [routerLink]="['/merchant/', parking.merchantRef.id]">
                                    <span [ngClass]="{ undefined: !parking.denormMerchantCompanyName }">
                                        {{ parking.denormMerchantCompanyName || 'Not set' }}
                                    </span>
                                </a>
                            </td>
                            <td><i class="fa-solid fa-tag"></i> {{ parking.niceType }}</td>
                            <td>
                                <ng-container *ngIf="parking.numberOfBays"><i class="fa-solid fa-car"></i> {{ parking.numberOfBays }}</ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="parking.bankDetailsRef"><i class="fa-solid fa-building-columns"></i> Yes</ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="parking.parkingLocation?.streetNumber"><i class="fa-solid fa-location-dot"></i> {{ parking.parkingLocation?.city }}</ng-container>
                            </td>
                            <!--                    <td>-->
                            <!--                        <ng-container *ngIf="parking.parkingStatus != parkingStatus.PUBLISHED">-->
                            <!--                            <a class="link-primary" (click)="approveParking(parking.$key)">-->
                            <!--                                Publish-->
                            <!--                            </a>-->
                            <!--                        </ng-container>-->
                            <!--                    </td>-->
                            <td>
                                <ng-container *ngIf="parking.serviceAgreementUrl">
                                    <a class="text-decoration-underline" [href]="parking.serviceAgreementUrl" target="_blank">View</a>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
