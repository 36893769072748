import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { Client, Occupant, occupantsPath, Parking, PaymentMethod, Subscription, subscriptionsPath } from '@parkupp/core'
import { ActivatedRoute } from '@angular/router'
import { doc, docData, DocumentData, DocumentReference } from '@angular/fire/firestore'

@Component({
    selector: 'app-admin-view-subscription',
    templateUrl: './admin-view-subscription.component.html',
    styleUrls: ['./admin-view-subscription.component.scss'],
})
export class AdminViewSubscriptionComponent implements OnInit {
    subscriptionKey: string
    subscription: Subscription
    qrData: any

    constructor(private adminSvc: AdminService, private activatedRoute: ActivatedRoute) {
        this.subscriptionKey = this.activatedRoute.snapshot.params['subscriptionKey']

        this.qrData = '{"type":"subscription","key":"5VT9RHW5OZObWsWABonG"}'

        docData(doc(this.adminSvc.firestore, subscriptionsPath(), this.subscriptionKey)).subscribe((documentData: any) => {
            console.log(documentData)
            let subscription = new Subscription(documentData)
            subscription.$key = this.subscriptionKey

            docData(documentData.parkingRef).subscribe((data) => {
                subscription.$parking = data as Parking
                subscription.$parking.$key = documentData.parkingRef.id
            })
            docData(documentData.paymentMethodRef).subscribe((data) => {
                subscription.$paymentMethod = data as PaymentMethod
                subscription.$paymentMethod.$key = documentData.paymentMethodRef.id
            })
            docData(documentData.clientRef).subscribe((data) => {
                subscription.$client = data as Client
                subscription.$client.$key = documentData.clientRef.id
            })
            if (documentData.occupantRefs) {
                subscription.$occupants = []
                documentData.occupantRefs.forEach((occupantsRef: DocumentReference<DocumentData>) => {
                    docData(occupantsRef).subscribe((data) => {
                        const occupant = new Occupant(data)
                        occupant.$key = occupantsRef.id
                        subscription.$occupants!.push(occupant)
                    })
                })
            }

            this.subscription = subscription
            console.log(this.subscription)
        })
    }

    ngOnInit(): void {}
}
