<admin-app-header></admin-app-header>
<div class="container-fluid">
    <br />
    <div class="row mb-5">
        <div class="col-md-2">
            <div class="card py-3" style="background-color: #d3d3d3">
                <h5 class="card-title text-center text-dark">
                    {{ this.totalBuildings }}
                    <br />
                    Active Buildings
                    <br />
                    ({{ this.totalUnpublishedBuildings }} Drafts)
                </h5>
            </div>
        </div>

        <div class="col-md-2">
            <div class="card py-3 text-center" style="background-color: #a8d5ba">
                <h5 class="card-title text-center text-dark">
                    {{ this.activeSubscriptions }} / {{ this.totalParkings }}
                    <br />
                    Active / Total Parkings
                    <br />
                    {{ (this.activeSubscriptions / this.totalParkings) * 100 | number: '1.2-2' }}% Capacity
                </h5>
            </div>
        </div>

        <div class="col-md-2">
            <div class="card py-3 text-center" style="background-color: #f7e7a9">
                <h5 class="card-title text-center text-dark">
                    {{ this.totalContractValue | formatRands }}
                    <br />
                    Total Contract Value (testing)
                </h5>
            </div>
        </div>

        <div class="col-md-2" *ngIf="this.overdueInvoices">
            <div class="card py-3 text-center" style="background-color: #f5a9a9">
                <h5 class="card-title text-center text-dark">
                    {{ this.overdueInvoices }}
                    <br />
                    Overdue Invoices
                    <br />
                    {{ this.overdueInvoicesTotal | formatRands }}
                </h5>
            </div>
        </div>

        <div class="col-md-2" *ngIf="this.overdueInvoices">
            <div class="card py-3 text-center" style="background-color: #d4a5c9">
                <h5 class="card-title text-center text-dark">
                    {{ this.unpaidInvoices }}
                    <br />
                    Invoices Not Paid Out
                    <br />
                    {{ this.totalUnpaidAmount | formatRands }}
                </h5>
            </div>
        </div>

        <div class="col-md-2" *ngIf="this.invoicesWithoutBankDetails">
            <div class="card py-3 text-center" style="background-color: #ffc107">
                <h5 class="card-title text-center text-dark">
                    {{ this.invoicesWithoutBankDetails.length }}
                    <br />
                    Invoices Missing Bank Details
                    <br />
                    {{ this.invoicesWithoutBankDetailsTotalValue | formatRands }}
                </h5>
            </div>
        </div>

        <div class="col-md-2" *ngIf="this.users">
            <div class="card py-3 text-center" style="background-color: #a9d3f5">
                <h5 class="card-title text-center text-dark">
                    {{ this.users }}
                    <br />
                    Users
                </h5>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="d-none d-sm-block col-lg-6 mb-5 mr-5">
            <div style="height: 150px">
                <h5 class="px-3">Monthly Turn over</h5>
                <ngx-charts-bar-vertical-stacked
                    [results]="chartMonthlyData"
                    [scheme]="chartMonthlyColorScheme"
                    [gradient]="false"
                    [xAxis]="true"
                    [yAxis]="false"
                    [legend]="false"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [animations]="true"
                    [showDataLabel]="true"
                    [noBarWhenZero]="true"
                >
                </ngx-charts-bar-vertical-stacked>
            </div>

            <br />
            <br />
            <div style="height: 150px">
                <h5 class="px-3 mt-3">Bays Booked</h5>
                <ngx-charts-bar-vertical-stacked
                    [results]="chartBaysData"
                    [scheme]="chartBaysColorScheme"
                    [gradient]="false"
                    [xAxis]="true"
                    [yAxis]="false"
                    [legend]="false"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [animations]="true"
                    [showDataLabel]="true"
                    [noBarWhenZero]="true"
                >
                </ngx-charts-bar-vertical-stacked>
            </div>
        </div>

        <div class="d-none d-sm-block col-lg-6 mb-5 mr-5" style="height: 300px">
            <h5 class="px-3">Full contract value by month</h5>
            <ngx-charts-bar-vertical-stacked
                [results]="chartSalesData"
                [scheme]="chartSalesColorScheme"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="false"
                [legend]="false"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [animations]="true"
                [showDataLabel]="true"
                [noBarWhenZero]="true"
            >
            </ngx-charts-bar-vertical-stacked>
        </div>
    </div>
    <br />
    <br />
    <div class="row">
        <div class="content col-lg-3">
            <h5 class="px-3">Subscriptions pending approval</h5>
            <table class="table table-striped table-hover ms-2">
                <thead>
                    <tr>
                        <th scope="col">Created</th>
                        <th scope="col">Status</th>
                        <th scope="col">Merchant</th>
                        <th scope="col">Parking</th>
                        <th scope="col">Client</th>
                        <th scope="col">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let subscription of inactiveSubscriptions">
                        <td>
                            <a [routerLink]="['/admin/subscription', subscription.$key]">
                                {{ subscription.createdAt | daysAgo }}
                            </a>
                        </td>
                        <td>
                            <span class="badge" [ngClass]="subscription.statusBadgeColour"> Paid </span>
                        </td>
                        <td>
                            <a [routerLink]="['/merchant/', subscription.$merchant?.$key]">
                                {{ subscription.$merchant?.companyName | slice: 0:20 }}
                                <span *ngIf="!subscription.$merchant?.companyName" class="undefined">??</span>
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['/parking/', subscription.$parking?.$key]">
                                {{ subscription.$parking?.title || '' }}
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['/client/', subscription.$client?.$key]">
                                {{ subscription.$client?.firstName || '' }}
                            </a>
                        </td>
                        <td>R{{ subscription.totalContractValue | formatCents }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="content col-lg-3">
            <h5 class="px-3">Invoices missing bank details</h5>
            <table class="table table-striped table-hover ms-2">
                <thead>
                    <tr>
                        <th scope="col">Merchant</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let merchant of this.noBankMerchantRefs">
                        <td>
                            <a [routerLink]="['/merchant/', merchant.id, 'profile', 'payout']">{{ merchant.denormMerchantCompanyName }}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="content col-lg-6">
            <h5 class="px-3">Subscriptions expiring within 10 days</h5>
            <table class="table table-striped table-hover ms-2">
                <thead>
                    <tr>
                        <th scope="col">Status</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Client</th>
                        <th scope="col">Parking</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let subscription of expiringSubscriptions">
                        <tr *ngIf="subscription.$client">
                            <td>
                                <ng-container *ngIf="subscription.renewalStatus == 'notRenewed'">
                                    <span class="badge bg-danger">
                                        {{ subscription.renewalStatus }}
                                    </span>
                                </ng-container>

                                <ng-container *ngIf="['renewedSameParking', 'renewedOtherParking'].includes(subscription.renewalStatus)">
                                    <span
                                        class="badge cursor"
                                        [ngClass]="{
                                            'bg-success': subscription.renewalStatus === 'renewedSameParking',
                                            'bg-warning': subscription.renewalStatus === 'renewedOtherParking'
                                        }"
                                    >
                                        <a [routerLink]="['/admin/subscription/', subscription.renewalSubscriptionRef.id]">
                                            {{ subscription.renewalStatus }}
                                        </a>
                                    </span>
                                </ng-container>
                            </td>
                            <td>
                                <a [routerLink]="['/admin/subscription/', subscription.$key]">
                                    {{ subscription.endDate | formatDate }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/client/', subscription.$client?.$key]">
                                    {{ subscription.$client?.firstName || 'Unknown' }}
                                    {{ subscription.$client?.lastName || '' }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/parking/', subscription.$parking?.$key]">
                                    {{ subscription.$parking?.title || '' }}
                                </a>
                            </td>
                            <td>
                                <ng-container *ngIf="subscription.$client.$email">
                                    {{ subscription.$client.$email }}
                                    <a href="mailto:{{ subscription.$client?.$email }}" target="_blank" class="icon-wrapper email">
                                        <i class="far fa-envelope"></i>
                                    </a>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="subscription.$client.$phoneNumber">
                                    {{ subscription.$client.$phoneNumber }}
                                    <a href="https://wa.me/{{ subscription.$client?.$phoneNumber }}" target="_blank" class="icon-wrapper whatsapp">
                                        <i class="fab fa-whatsapp"></i>
                                    </a>
                                    <a href="tel:{{ subscription.$client?.$phoneNumber }}" target="_blank" class="icon-wrapper tel">
                                        <i class="fas fa-phone"></i>
                                    </a>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
