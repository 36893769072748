<admin-app-header></admin-app-header>

<div *ngIf="exceptionAlerts; else loading">
    <div class="container-fluid">
        <button (click)="globalService.exportcsv('all-exception-alerts')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>
        <h2 class="float-start d-inline-block">Exception Alerts</h2>

        <div class="content col-lg-12">
            <table class="table table-striped table-hover table-sm" id="all-exception-alerts">
                <thead>
                <tr>
                    <th scope="col">Timestamp</th>
                    <th scope="col">Severity</th>
                    <th scope="col">Message</th>
                    <th scope="col">Function</th>
                    <th scope="col">File</th>
                    <th scope="col">Line</th>
                    <th scope="col">Reviewed</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let alert of exceptionAlerts" [routerLink]="['/admin/exception', alert.$key]">
                    <td>{{ alert.timestamp | date: 'medium' }}</td>
                    <td>
                        <span [ngClass]="{'bg-danger': alert.alertSeverity === 'critical', 'bg-warning': alert.alertSeverity === 'high', 'bg-info': alert.alertSeverity === 'info'}" class="badge">
                            {{ alert.alertSeverity }}
                        </span>
                    </td>
                    <td>
                        {{ alert.comment }} {{ alert.message }}
                        <ng-container *ngIf="alert.json">
                            <i class="fas fa-database" title="{{alert.json}}"></i>
                        </ng-container>
                    </td>
                    <td>{{ alert.functionName }}</td>
                    <td>{{ alert.fileName }}</td>
                    <td>{{ alert.lineNumber }}</td>
                    <td>
                        <span [ngClass]="{'text-success': alert.reviewed, 'text-danger': !alert.reviewed}">
                            {{ alert.reviewed ? 'Yes' : 'No' }}
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
            <a (click)="loadMore()" *ngIf="showLoadMoreButton">Load more...</a>
        </div>
    </div>
</div>
<ng-template #loading>
    <p>Loading exception alerts...</p>
</ng-template>
