import { Injectable } from '@angular/core'
import { collection, doc, docData, DocumentData, Firestore, getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter } from '@angular/fire/firestore'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { AuthenticationService } from './authentication.service'
import { from, map, Observable } from 'rxjs'
import { ExceptionAlert, FirestoreStructure } from '@parkupp/core'
import { ClientService } from './client.service'
import { MerchantService } from './merchant.service'
import { ParkingService } from './parking.service'
import { SubscriptionService } from './subscription.service'

@Injectable({
    providedIn: 'root',
})
export class ExceptionAlertService {
    collection = collection(this.firestore, FirestoreStructure.EXCEPTION_ALERTS)

    constructor(
        private authSvc: AuthenticationService,
        public firestore: Firestore,
        private router: Router,
        private http: HttpClient,
        private clientService: ClientService,
        private merchantService: MerchantService,
        private parkingService: ParkingService,
        private subscriptionService: SubscriptionService
    ) {}

    get(id: string): Observable<any> {
        const docRef = doc(this.firestore, `${FirestoreStructure.EXCEPTION_ALERTS}/${id}`)
        return docData(docRef, { idField: '$key' })
    }

    list(startAfterDoc?: QueryDocumentSnapshot<ExceptionAlert>, pageSize: number = 100): Observable<QueryDocumentSnapshot<DocumentData>[]> {
        let exceptionsQuery = query(this.collection, orderBy('timestamp', 'desc'), limit(pageSize))

        if (startAfterDoc) {
            exceptionsQuery = query(exceptionsQuery, startAfter(startAfterDoc))
        }

        return from(getDocs(exceptionsQuery)).pipe(
            map((snapshot) => snapshot.docs) // Map from QuerySnapshot to an array of QueryDocumentSnapshots
        )
    }
}
