import { Injectable } from '@angular/core'
import {
    addDoc,
    collection,
    collectionData,
    doc,
    docData,
    DocumentData,
    Firestore,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    QueryDocumentSnapshot,
    serverTimestamp,
    startAfter,
    updateDoc,
    where,
    writeBatch,
} from '@angular/fire/firestore'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { AuthenticationService } from './authentication.service'
import { from, map, Observable, of, switchMap, firstValueFrom } from 'rxjs'
import { FirestoreStructure, Invoice, InvoiceStatus, Merchant, Payout, VariableStructure } from '@parkupp/core'
import { ClientService } from './client.service'
import { MerchantService } from './merchant.service'
import { ParkingService } from './parking.service'
import { SubscriptionService } from './subscription.service'
import * as firestore from 'firebase/firestore'
import { PayoutStatusEnum } from '@parkupp/core/src/models/invoicing/payout.model'
import { PayoutBatch } from '../../../../parkupp-model/src/models/invoicing/payout-batch.model'
import { VariableService } from './variable.service'

@Injectable({
    providedIn: 'root',
})
export class PayoutBatchService {
    collection = collection(this.firestore, FirestoreStructure.PAYOUT_BATCHES)

    constructor(public firestore: Firestore, private variableService: VariableService) {}

    async getPayoutBatch(payoutBatchKey: string): Promise<PayoutBatch> {
        const docRef = doc(this.collection, payoutBatchKey)
        const docSnap = await getDoc(docRef)
        const payoutBatch = new PayoutBatch(docSnap.data())
        payoutBatch.$key = docSnap.id
        return payoutBatch
    }

    async createOrUpdate(payoutBatch: PayoutBatch) {
        payoutBatch.updatedAt = serverTimestamp() as firestore.Timestamp
        payoutBatch.payoutBatchNumber = await this.variableService.getNewPayoutBatchNumber()
        if (!payoutBatch.$key) {
            payoutBatch.createdAt = serverTimestamp() as firestore.Timestamp
            const docRef = await addDoc(this.collection, payoutBatch.parse())
            return docRef
        } else {
            await updateDoc(doc(this.collection, payoutBatch.$key), payoutBatch.parse())
            return doc(this.collection, payoutBatch.$key)
        }
    }
    async getPayoutBatches(): Promise<PayoutBatch[]> {
        const q = query(this.collection, orderBy('createdAt', 'desc'))
        const querySnapshot = await getDocs(q)
        const payoutBatches = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                const data = new PayoutBatch(doc.data())
                data.$key = doc.id
                return data
            })
        )
        return payoutBatches
    }
}
