"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadCity = exports.LeadUser = exports.LeadClosedReason = exports.LeadStatus = exports.PaymentMethodType = exports.ChangeLogsEntityEnum = exports.AlertSeverity = exports.SlackChannel = void 0;
var SlackChannel;
(function (SlackChannel) {
    SlackChannel["DEVELOPMENT"] = "development";
    SlackChannel["SYSTEM"] = "system";
    SlackChannel["CRITICAL"] = "critical_alerts";
})(SlackChannel = exports.SlackChannel || (exports.SlackChannel = {}));
var AlertSeverity;
(function (AlertSeverity) {
    AlertSeverity["CRITICAL"] = "critical";
    AlertSeverity["MAJOR"] = "major";
    AlertSeverity["MINOR"] = "minor";
    AlertSeverity["WARNING"] = "warning";
    AlertSeverity["INFO"] = "info";
})(AlertSeverity = exports.AlertSeverity || (exports.AlertSeverity = {}));
var ChangeLogsEntityEnum;
(function (ChangeLogsEntityEnum) {
    ChangeLogsEntityEnum["INVOICE"] = "invoice";
    ChangeLogsEntityEnum["CLIENT"] = "client";
})(ChangeLogsEntityEnum = exports.ChangeLogsEntityEnum || (exports.ChangeLogsEntityEnum = {}));
var PaymentMethodType;
(function (PaymentMethodType) {
    PaymentMethodType["PAYGATE"] = "paygate";
    PaymentMethodType["PAYFAST"] = "payfast";
    PaymentMethodType["EFT"] = "eft";
})(PaymentMethodType = exports.PaymentMethodType || (exports.PaymentMethodType = {}));
var LeadStatus;
(function (LeadStatus) {
    LeadStatus["NEW"] = "New";
    LeadStatus["IN_PROGRESS"] = "In Progress";
    LeadStatus["HOT"] = "Hot";
    LeadStatus["COLD"] = "Cold";
    LeadStatus["CONVERTED"] = "Converted";
    LeadStatus["CLOSED"] = "Closed";
})(LeadStatus = exports.LeadStatus || (exports.LeadStatus = {}));
var LeadClosedReason;
(function (LeadClosedReason) {
    LeadClosedReason["NO_AVAILABILITY"] = "No Availability in Area";
    LeadClosedReason["NO_RESPONSE"] = "No Response";
    LeadClosedReason["DUPLICATE"] = "Duplicate";
    LeadClosedReason["BUDGET_CONSTRAINTS"] = "Budget Constraints";
    LeadClosedReason["TIMING_ISSUES"] = "Timing Issues";
    LeadClosedReason["CHOSE_COMPETITOR"] = "Chose Competitor";
    LeadClosedReason["OTHER"] = "Other";
})(LeadClosedReason = exports.LeadClosedReason || (exports.LeadClosedReason = {}));
var LeadUser;
(function (LeadUser) {
    LeadUser["KWANELE"] = "Kwanele";
    LeadUser["UMAR"] = "Umar";
})(LeadUser = exports.LeadUser || (exports.LeadUser = {}));
var LeadCity;
(function (LeadCity) {
    LeadCity["JOHANNESBURG"] = "Johannesburg";
    LeadCity["CAPE_TOWN"] = "Cape Town";
    LeadCity["DURBAN"] = "Durban";
    LeadCity["PRETORIA"] = "Pretoria";
    LeadCity["PORT_ELIZABETH"] = "Port Elizabeth";
    LeadCity["BLOEMFONTEIN"] = "Bloemfontein";
    LeadCity["EAST_LONDON"] = "East London";
    LeadCity["NELSPRUIT"] = "Nelspruit";
    LeadCity["KIMBERLEY"] = "Kimberley";
    LeadCity["POLOKWANE"] = "Polokwane";
    LeadCity["RUSTENBURG"] = "Rustenburg";
})(LeadCity = exports.LeadCity || (exports.LeadCity = {}));
