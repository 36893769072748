import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { addDoc, collection, doc, docData, serverTimestamp, updateDoc } from '@angular/fire/firestore'
import { ContractStatus, Merchant, merchantsPath, Parking, parkingsPath, Plan, Subscription, subscriptionsPath, SubscriptionType, Term } from '@parkupp/core'
import { AdminService } from '../../../services/admin.service'
import * as moment from 'moment'
import * as firestore from 'firebase/firestore'
import { ActivatedRoute, Router } from '@angular/router'
import { MerchantService } from '../../../services/merchant.service'
import { ParkingService } from '../../../services/parking.service'
import { ClientService } from '../../../services/client.service'
import { SubscriptionService } from '../../../services/subscription.service'
import Swal from 'sweetalert2'

@Component({
    selector: 'app-admin-subscription-manual-create',
    templateUrl: './admin-subscription-manual-create.component.html',
    styleUrls: ['./admin-subscription-manual-create.component.scss'],
})
export class AdminSubscriptionManualCreateComponent implements OnInit {
    subscriptionKey: string
    formData: FormGroup
    formLoading: boolean = false
    subscription: Subscription
    merchants: Merchant[]
    parkingLookup: Parking[]

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private adminService: AdminService,
        private activatedRoute: ActivatedRoute,
        private merchantService: MerchantService,
        private parkingService: ParkingService,
        private clientService: ClientService,
        private subscriptionService: SubscriptionService
    ) {
        this.subscriptionKey = this.activatedRoute.snapshot.params['subscriptionKey']

        this.formData = this.formBuilder.group({
            startDate: [moment().add(1, 'M').startOf('month').format('yyyy-MM-DD'), []],
            endDate: [moment().add(1, 'M').endOf('month').format('yyyy-MM-DD'), []],
            baysBooked: [1, []],
            feeInCents: [1200, []],
            contractLength: [1, []],
            merchantName: [''],
            merchantRef: [''],
            parkingName: [''],
            clientName: [''],
            clientRef: [''],
            parkingRef: [''],
        })

        this.formData.controls['contractLength'].valueChanges.subscribe((value) => this.formChanges({ contractLength: value }))
        this.formData.controls['startDate'].valueChanges.subscribe((value) => this.formChanges({ startDate: value }))

        if (this.subscriptionKey) {
            this.getEditingSubscription(this.subscriptionKey)
        } else {
            this.subscription = new Subscription()
        }

        this.merchantService.list().subscribe((merchants) => {
            this.merchants = merchants
        })
        this.parkingService.list().subscribe((parkings) => {
            this.parkingLookup = parkings
        })
    }

    getEditingSubscription(subscriptionKey: string) {
        docData(doc(this.adminService.firestore, subscriptionsPath(), subscriptionKey)).subscribe((documentData: any) => {
            this.subscription = new Subscription(documentData)
            this.subscription.$key = subscriptionKey

            console.log(this.subscription)
            this.formData.patchValue({
                startDate: moment(this.subscription.startDate.toDate()).format('yyyy-MM-DD'),
                endDate: moment(this.subscription.endDate.toDate()).format('yyyy-MM-DD'),
                baysBooked: this.subscription.baysBooked,
                clientName: this.subscription.manualClientName,
                feeInCents: this.subscription.plan.feeInCents / 100,
                contractLength: this.subscription.period,
                parkingName: this.subscription.manualParkingName,
            })

            if (documentData.merchantRef) {
                docData(documentData.merchantRef).subscribe((data) => {
                    this.subscription.$merchant = new Merchant(data)
                    this.subscription.$merchant.$key = documentData.merchantRef.id

                    this.formData.patchValue({
                        merchantRef: this.subscription.merchantRef,
                        merchantName: this.subscription.$merchant?.companyName,
                    })
                })
            }
            if (documentData.parkingRef) {
                docData(documentData.parkingRef).subscribe((data) => {
                    this.subscription.$parking = new Parking(data)
                    this.subscription.$parking.$key = documentData.parkingRef.id

                    this.formData.patchValue({
                        parkingRef: this.subscription.parkingRef,
                        parkingName: this.subscription.$parking?.title,
                    })
                })
            }
        })
    }

    ngOnInit(): void {}

    formChanges(changes: { contractLength?: number; startDate?: number }) {
        let contractLength = changes.contractLength ? changes.contractLength : this.formData.value.contractLength
        let startDate = changes.startDate ? changes.startDate : this.formData.value.startDate

        if (changes.contractLength || changes.startDate) {
            this.updateEndDate(Term.MONTHLY, startDate, contractLength)
        }
    }

    updateEndDate(term: string, startDate: Date, contractLength: any) {
        let lengthUnit = 'M'
        if (term == Term.MONTHLY) {
            lengthUnit = 'M'
        }
        if (term == Term.DAILY) {
            lengthUnit = 'd'
        }
        if (term == Term.ONCEOFF) {
            lengthUnit = 'd'
        }

        const newEndDate = moment(startDate).add(contractLength, lengthUnit).subtract(1, 'day')
        this.formData.controls['endDate'].setValue(newEndDate.format('yyyy-MM-DD'))
    }

    async onSubmit() {
        console.log(this.formData.value)
        this.formLoading = true

        if (!this.subscription.$key) {
            this.subscription.createdAt = serverTimestamp() as firestore.Timestamp
            this.subscription.updatedAt = serverTimestamp() as firestore.Timestamp
        }

        const plan = new Plan({
            feeInCents: this.formData.value.feeInCents * 100,
            term: Term.MONTHLY,
        })

        this.clientService.getFromEmail(this.formData.value.clientEmail).subscribe((client) => {})

        this.subscription.type = SubscriptionType.manual
        this.subscription.baysBooked = this.formData.value.baysBooked
        this.subscription.startDate = firestore.Timestamp.fromDate(new Date(this.formData.value.startDate))
        this.subscription.endDate = firestore.Timestamp.fromDate(new Date(this.formData.value.endDate))
        this.subscription.status = ContractStatus.active
        this.subscription.plan = plan
        this.subscription.period = this.formData.value.contractLength
        this.subscription.manualParkingName = this.formData.value.manualParkingName

        this.subscription.manualClientName = this.formData.value.clientName

        if (this.formData.value.merchantRef) {
            this.subscription.merchantRef = this.formData.value.merchantRef

            const merchant: Merchant = await this.merchantService.getMerchant(this.formData.value.merchantRef.id)
            this.subscription.denormMerchantCompanyName = merchant.companyName
        }

        if (this.formData.value.parkingRef) {
            this.subscription.parkingRef = this.formData.value.parkingRef

            const parking: Parking = await this.parkingService.getStatic(this.formData.value.parkingRef.id)
            this.subscription.denormParkingTitle = parking.title
        }

        // this.subscription.manualClientName = this.formData.value.clientRef

        // const clientDocRef = doc(this.adminService.firestore, clientsPath(), this.formData.value.clientRef)
        // this.subscription.clientRef = clientDocRef as any

        // client: this.clientService.activeClient!,
        // subscription.parking = this.parking
        // subscription.plan = this.selectedPlan
        // subscription.contractLength = this.formData.value.contractLength

        // subscription.occupantKeys = this.formData.value.occupants

        console.log(this.subscription)
        if (this.subscription.$key) {
            updateDoc(doc(this.adminService.firestore, subscriptionsPath(), this.subscription.$key), this.subscription.parse()).then(() => {
                this.formLoading = false
                this.router.navigate([`/admin/subscriptions`])
                return
            })
        } else {
            addDoc(collection(this.adminService.firestore, subscriptionsPath()), this.subscription.parse()).then((docRef) => {
                this.formLoading = false
                this.router.navigate([`/admin/subscriptions`])
                return
            })
        }
    }

    onMerchantSelect(selectedMerchant: Merchant): void {
        if (selectedMerchant) {
            // this.selectedMerchantKey = selectedMerchant.$key;
            const docRef = doc(this.adminService.firestore, `${merchantsPath()}/${selectedMerchant.$key}`)
            this.formData.controls['merchantRef'].setValue(docRef)
        }
    }

    onParkingSelect(selectedParking: Parking): void {
        if (selectedParking) {
            // this.selectedParkingKey = selectedParking.$key;
            const docRef = doc(this.adminService.firestore, `${parkingsPath()}/${selectedParking.$key}`)
            this.formData.controls['parkingRef'].setValue(docRef)
        } else {
            this.formData.controls['parkingName'].setValue('')
        }
    }

    async deleteSubscription() {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this subscription?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        })

        if (result.isConfirmed) {
            await this.subscriptionService.delete(this.subscriptionKey)
            this.router.navigate(['/admin/subscriptions'])
        }
    }
}
