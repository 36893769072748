import { Component, OnInit } from '@angular/core'
import { AdminChangeLog, AngularRoutes, ChangeLogsEntityEnum, ContractStatus, Invoice, InvoiceStatus, Subscription, subscriptionsPath } from '@parkupp/core'
import { collection, collectionData, DocumentData, orderBy, query, Query, serverTimestamp, Timestamp, where } from '@angular/fire/firestore'
import { GlobalService } from '../../../services/global.service'
import { ActivatedRoute, Router } from '@angular/router'
import { AdminService } from '../../../services/admin.service'
import { InvoiceService } from '../../../services/invoice.service'
import * as moment from 'moment/moment'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { ClientService } from 'src/app/services/client.service'
import Swal from 'sweetalert2'
import { AdminChangeLogService } from 'src/app/services/admin-change-log.service'

@Component({
    selector: 'app-admin-list-invoices',
    templateUrl: './admin-list-invoices.component.html',
    styleUrls: ['./admin-list-invoices.component.scss'],
})
export class AdminListInvoicesComponent implements OnInit {
    subscriptions: Subscription[] = []
    inactiveContractStatuses: ContractStatus[]
    ref: Query<DocumentData>
    // invoices$: Observable<Invoice[]>
    readonly AngularRoutes = AngularRoutes
    invoices: Invoice[] = []
    lastVisible: any
    showLoadMoreButton: boolean = false
    InvoiceStatus = InvoiceStatus
    invoiceStates: InvoiceStatus[] = Object.values(InvoiceStatus)

    today: number = moment().toDate().getTime()
    protected readonly Invoice = Invoice
    filteredInvoices: Invoice[] = []
    status: InvoiceStatus = InvoiceStatus.DUE
    selectedInvoice: Invoice | null = null

    constructor(
        public globalService: GlobalService,
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private adminService: AdminService,
        private invoiceService: InvoiceService,
        private clientService: ClientService,
        private adminChangeLogService: AdminChangeLogService
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params['status']) {
                this.status = params['status'] as InvoiceStatus
            }
        })
        const activeContractStatuses: ContractStatus[] = [ContractStatus.paymentInitiated, ContractStatus.active, ContractStatus.bouncing, ContractStatus.renewing]
        const ref = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('createdAt', 'desc'), where('status', 'in', activeContractStatuses))
        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.subscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData, documentData.documentId)
                this.subscriptions.push(subscription)
            })
            this.loadMore()
        })
    }

    loadMore() {
        this.showLoadMoreButton = true
        this.invoices = []
        this.invoiceService.list(this.lastVisible, 200, this.status).subscribe((data) => {
            if (data.length > 0) {
                const newInvoices = data.map((doc) => {
                    const invoice = new Invoice(doc.data())
                    invoice.$key = doc.id
                    // Match subscription to the invoice
                    invoice.$subscription = this.subscriptions.find((sub) => sub.$key === invoice.subscriptionRef?.id)
                    return invoice
                }) // Cast each document's data to the Invoice type
                this.invoices = [...this.invoices, ...newInvoices]
                this.lastVisible = data[data.length - 1] // Set the last document snapshot
                if (data.length < 200) {
                    this.showLoadMoreButton = false
                } else {
                    this.showLoadMoreButton = true
                }
            } else {
                this.showLoadMoreButton = false
            }
        })
    }

    changeStatus(event: any) {
        this.status = event.target.value as InvoiceStatus
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { status: this.status },
            queryParamsHandling: 'merge',
        })
        this.filterInvoices()
    }

    filterInvoices() {
        this.lastVisible = null

        this.loadMore()
    }

    async showContextMenu(event: MouseEvent, invoice: Invoice) {
        this.globalService.contextMenuItems = []
        this.selectedInvoice = invoice
        this.globalService.showContextMenu(event)
        const statusActions = [
            { icon: 'fa fa-check', label: 'Mark As Paid', status: InvoiceStatus.PAID, comment: 'Marked as paid' },
            { icon: 'fa fa-clock', label: 'Mark As Due', status: InvoiceStatus.DUE, comment: 'Marked as due' },
            { icon: 'fa fa-times', label: 'Mark As Refunded', status: InvoiceStatus.REFUNDED, comment: 'Marked as refunded' },
            { icon: 'fa fa-times', label: 'Mark As Cancelled', status: InvoiceStatus.CANCELLED, comment: 'Marked as cancelled' },
            { icon: 'fa fa-times', label: 'Mark As Client Breeched', status: InvoiceStatus.BREECHED, comment: 'Marked as client breeched' },
        ]
        statusActions.forEach((action) => {
            if (invoice.status !== action.status) {
                this.globalService.contextMenuItems.push({
                    icon: action.icon,
                    label: action.label,
                    action: async () => {
                        this.updateInvoiceStatus(invoice, action.status, action.comment)
                    },
                })
            }
        })
    }

    async updateInvoiceStatus(invoice: Invoice, status: InvoiceStatus, comment: string) {
        const user = await this.authService.waitForUid()
        const admin = await this.clientService.getClientByUid(user.uid)
        if (admin) {
            const adminRef = this.clientService.getDocRef(admin.$key!)
            const invoiceRef = this.invoiceService.getDocRef(invoice.$key!)

            const changeLog = new AdminChangeLog({
                updatedEntity: ChangeLogsEntityEnum.INVOICE,
                invoiceRef: invoiceRef as any,
                clientRef: adminRef as any,
                comment: `${comment} by ${admin.firstName}`,
                createdAt: serverTimestamp() as Timestamp,
            })
            await this.adminChangeLogService.addOrUpdate(changeLog)

            if (status === InvoiceStatus.PAID) {
                await this.invoiceService.markAsPaid(invoice)
            } else if (status === InvoiceStatus.BREECHED) {
                await this.invoiceService.markAsClientBreeched(invoice)
            } else if (status === InvoiceStatus.REFUNDED) {
                await this.invoiceService.markAsRefunded(invoice)
            } else if (status === InvoiceStatus.CANCELLED) {
                await this.invoiceService.markAsCancelled(invoice)
            } else if (status === InvoiceStatus.DUE) {
                await this.invoiceService.markAsDue(invoice)
            }
        }
        this.globalService.hideContextMenu()
        location.reload()
    }
}
