import { Injectable } from '@angular/core'
import {
    addDoc,
    collection,
    collectionData,
    doc,
    docData,
    DocumentData,
    Firestore,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    QueryDocumentSnapshot,
    serverTimestamp,
    startAfter,
    updateDoc,
    where,
    writeBatch,
} from '@angular/fire/firestore'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { AuthenticationService } from './authentication.service'
import { from, map, Observable, of, switchMap, firstValueFrom } from 'rxjs'
import { FirestoreStructure, Invoice, InvoiceStatus, Merchant, Payout, PayoutBatch, PayoutStatusEnum, VariableStructure } from '@parkupp/core'
import { ClientService } from './client.service'
import { MerchantService } from './merchant.service'
import { ParkingService } from './parking.service'
import { SubscriptionService } from './subscription.service'
import * as firestore from 'firebase/firestore'
import { VariableService } from './variable.service'

@Injectable({
    providedIn: 'root',
})
export class PayoutService {
    collection = collection(this.firestore, FirestoreStructure.PAYOUTS)

    constructor(
        private authSvc: AuthenticationService,
        public firestore: Firestore,
        private router: Router,
        private http: HttpClient,
        private clientService: ClientService,
        private merchantService: MerchantService,
        private parkingService: ParkingService,
        private subscriptionService: SubscriptionService,
        private variableService: VariableService
    ) {}

    getDocRef(payoutKey: string): any {
        return doc(this.firestore, `${FirestoreStructure.PAYOUTS}/${payoutKey}`)
    }

    async getPayout(payoutKey: string): Promise<Payout> {
        const docSnap = await getDoc(doc(this.collection, payoutKey))
        const payout = new Payout(docSnap.data())
        payout.$key = docSnap.id
        return payout
    }
    async getPayoutsByMerchant(merchantKey: string): Promise<Payout[]> {
        const q = query(this.collection, where('merchantRef', '==', merchantKey), orderBy('createdAt', 'desc'))
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map((doc) => {
            const payout = new Payout(doc.data())
            payout.$key = doc.id
            return payout
        })
    }

    async createOrUpdate(payout: Payout) {
        payout.updatedAt = serverTimestamp() as firestore.Timestamp
        if (!payout.$key) {
            const newPayoutNumber = await this.variableService.getNewPayoutNumber()
            payout.createdAt = serverTimestamp() as firestore.Timestamp
            payout.payoutNumber = newPayoutNumber
            return addDoc(this.collection, payout.parse()).then((docRef) => {
                return docRef
            })
        } else {
            return updateDoc(doc(this.collection, payout.$key), payout.parse()).then(() => {
                return doc(this.collection, payout.$key)
            })
        }
    }
    async getUnbatchedPayouts(): Promise<Payout[]> {
        const q = query(this.collection, where('status', '==', PayoutStatusEnum.DUE), orderBy('createdAt', 'desc'))
        const querySnapshot = await getDocs(q)
        const payouts = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                const data = new Payout(doc.data())
                data.$key = doc.id
                if (data.merchantRef) {
                    const merchantData = await firstValueFrom(docData(data.merchantRef as any))
                    data.$merchant = new Merchant(merchantData)
                    data.$merchant.$key = data.merchantRef.id
                }
                return data
            })
        )
        return payouts
    }

    async getProformaPayouts(): Promise<Payout[]> {
        const q = query(this.collection, orderBy('createdAt', 'desc'))
        const querySnapshot = await getDocs(q)
        const payouts = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                const data = new Payout(doc.data())
                data.$key = doc.id
                if (data.merchantRef) {
                    const merchantData = await firstValueFrom(docData(data.merchantRef as any))
                    data.$merchant = new Merchant(merchantData)
                    data.$merchant.$key = data.merchantRef.id
                }
                return data
            })
        )
        return payouts
    }
}
