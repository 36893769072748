import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Client, Invoice, InvoiceLineItem, InvoiceStatus, Merchant, Parking } from '@parkupp/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MerchantService } from '../../../../services/merchant.service'
import { ParkingService } from '../../../../services/parking.service'
import { ClientService } from '../../../../services/client.service'
import { serverTimestamp } from '@angular/fire/firestore'

import * as firestore from 'firebase/firestore'
import { InvoiceService } from '../../../../services/invoice.service'

@Component({
    selector: 'app-admin-create-invoice',
    templateUrl: './admin-create-invoice.component.html',
    styleUrls: ['./admin-create-invoice.component.scss'],
})
export class AdminCreateInvoiceComponent implements OnInit {
    subscriptionKey: string
    formData: FormGroup
    formLoading: boolean = false
    merchants: Merchant[]
    parkingLookup: Parking[]
    clientLookup: Client[]
    invoice: Invoice

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private merchantService: MerchantService,
        private parkingService: ParkingService,
        private clientService: ClientService,
        private invoiceService: InvoiceService
    ) {
        this.subscriptionKey = this.activatedRoute.snapshot.params['subscriptionKey']

        this.formData = this.formBuilder.group({
            feeInRands: [null, Validators.required],
            parkingName: [''],
            clientName: [''],
            confirmMerchantCompanyName: [''],
            confirmClientEmail: [''],
            confirmClientPhone: [''],
            lineItemDescription: [''],
        })

        this.invoice = new Invoice()

        this.parkingService.list().subscribe((parkings) => {
            this.parkingLookup = parkings
        })
        this.clientService.list().subscribe((clients) => {
            this.clientLookup = clients
        })
    }

    ngOnInit(): void {}

    onParkingSelect(selectedParking: Parking): void {
        this.formData.controls['confirmMerchantCompanyName'].setValue(selectedParking.denormMerchantCompanyName)
        if (selectedParking.plans && selectedParking.plans[0]) {
            this.formData.controls['feeInRands'].setValue(selectedParking.plans[0].feeInCents / 100)
        }

        this.invoice.parkingRef = this.parkingService.getDocRef(selectedParking.$key!) as any
        this.invoice.denormParkingTitle = selectedParking.title

        this.invoice.merchantRef = this.merchantService.getDocRef(selectedParking.merchantRef.id) as any
        this.invoice.denormMerchantCompanyName = selectedParking.denormMerchantCompanyName
    }

    onClientSelect(selectedClient: Client): void {
        this.clientService.adminGetEmailFromClientKey(selectedClient.$key!).subscribe((privateClient) => {
            this.formData.controls['confirmClientEmail'].setValue(privateClient.email)
            this.formData.controls['confirmClientPhone'].setValue(privateClient.phoneNumber)
        })

        this.invoice.clientRef = this.clientService.getDocRef(selectedClient.$key!) as any
        this.invoice.denormClientFirstName = selectedClient.firstName
        this.invoice.denormClientLastName = selectedClient.lastName
    }

    onSubmit() {
        this.formLoading = true
        this.invoice.createdAt = serverTimestamp() as firestore.Timestamp
        this.invoice.updatedAt = serverTimestamp() as firestore.Timestamp
        this.invoice.status = InvoiceStatus.DUE
        this.invoice.amountTotalDue = this.formData.controls['feeInRands'].value

        const lineItems: InvoiceLineItem[] = []
        lineItems.push(
            new InvoiceLineItem({
                description: this.formData.value.lineItemDescription,
                quantity: 1,
                unitPrice: this.formData.value.feeInRands,
            })
        )
        this.invoice.lineItems = lineItems

        this.invoiceService.create(this.invoice).then(async () => {
            this.formLoading = false
            await this.router.navigate([`/admin/invoices`])
            return
        })
    }
}
