<admin-app-header></admin-app-header>
<br />

<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="mb-5">Create / Edit Manual Subscription</h1>
        </div>
    </div>
    <div class="col-12">
        <form (ngSubmit)="onSubmit()" [formGroup]="formData" autocomplete="off" novalidate>
            <div class="row mt-3">
                <div class="form-group col-6">
                    <label>Start Date <span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="startDate" name="startDate" type="date" />
                </div>

                <div class="form-group col-6">
                    <label>End Date</label>
                    <input DISABLED class="form-control" formControlName="endDate" name="endDate" type="date" />
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-6">
                    <label>Period</label>
                    <input DISABLED class="form-control" type="text" value="Monthly" />
                </div>

                <div class="form-group col-6">
                    <label>Fee <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <div class="input-group-text">
                            <span>R</span>
                        </div>
                        <input class="form-control" formControlName="feeInCents" inputCurrency name="feeInCents" placeholder="R" type="text" />
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="form-group col-6">
                    <label>Bays <span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="baysBooked" name="baysBooked" type="number" />
                </div>

                <div class="form-group col-6">
                    <label for="input-contractLength">Contract Duration <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input class="form-control" formControlName="contractLength" id="input-contractLength" min="1" name="contractLength" type="number" />
                        <div class="input-group-text">
                            <span>Months</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-5">
                    <label>Client Name (manual)</label>
                    <input class="form-control" formControlName="clientName" name="clientName" type="text" />
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-5">
                    <label>Parking Name </label>
                    <input
                        (typeaheadOnSelect)="onParkingSelect($event.item)"
                        [typeaheadItemTemplate]="parkingTemplate"
                        [typeaheadOptionField]="'title'"
                        [typeahead]="parkingLookup"
                        class="form-control"
                        formControlName="parkingName"
                        placeholder="Search for a Parking"
                        type="text"
                    />
                    <ng-template #parkingTemplate let-index="index" let-model="item">
                        {{ model.title }}
                    </ng-template>
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-6">
                    <label>Merchant Name </label>
                    <input
                        (typeaheadOnSelect)="onMerchantSelect($event.item)"
                        [typeaheadItemTemplate]="merchantTemplate"
                        [typeaheadOptionField]="'companyName'"
                        [typeahead]="merchants"
                        class="form-control"
                        formControlName="merchantName"
                        placeholder="Search for a Merchant"
                        type="text"
                    />
                    <ng-template #merchantTemplate let-index="index" let-model="item">
                        {{ model.companyName }}
                    </ng-template>
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-6">
                    <button [disabled]="!formData.valid || formLoading == true" class="btn btn-primary my-3" type="submit">Save</button>
                </div>
                <div class="form-group col-6">
                    <button (click)="deleteSubscription()" *ngIf="this.subscriptionKey" class="btn btn-danger my-3 float-end" type="submit">Delete Subscription</button>
                </div>
            </div>
        </form>
    </div>
</div>
