<div class="container-fluid py-5 bg-dark-blue">
    <div class="container">
        <div class="row text-primary text-center">
            <h2 class="text-white">Book With Confidence</h2>
            <p class="text-white">5-star reviews from our happy customers</p>
        </div>
        <div class="row">
            <swiper-container init="false" class="swiper-container-testimonials">
                <swiper-slide *ngFor="let testimonial of testimonials">
                    <div class="text-center">
                        <img [src]="testimonial.imageUrl" style="border: 2px solid white; border-radius: 50%" width="80px" />
                        <div class="card-body">
                            <h5>{{ testimonial.name }}</h5>
                            <small>{{ testimonial.review }}</small>
                            <br /><br />
                            <i *ngFor="let star of testimonial.stars" class="fas fa-star text-primary"></i>
                            <br />
                        </div>
                    </div>
                </swiper-slide>
            </swiper-container>
        </div>
    </div>
</div>
