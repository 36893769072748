<admin-app-header></admin-app-header>
<div class="container-fluid" *ngIf="payoutBatch">
    <div class="row">
        <div class="col-6 d-flex flex-column justify-content-between">
            <h1 class="">Payout Batch: {{ payoutBatch.payoutBatchNumber }}</h1>
            <p>
                Status:
                <ng-container *ngIf="payoutBatch.payoutDate">
                    <span class="badge bg-success text-dark">{{ payoutBatch.payoutDate.toDate() | date: 'dd MMM yyyy' }}</span>
                </ng-container>
                <ng-container *ngIf="!payoutBatch.payoutDate">
                    <span class="badge bg-danger text-light">Unpaid</span>
                </ng-container>
            </p>
            <p>Merchants: {{ payoutBatch.merchantRefs.length }}</p>
            <p>Invoices: {{ payoutBatch.invoiceRefs.length }}</p>
        </div>
        <div class="col-6">
            <button class="btn btn-primary" (click)="completePayoutBatch()">Mark all as paid</button>
        </div>
    </div>
    <div class="content col-lg-12">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Proforma</th>
                    <th scope="col">Invoices</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Beneficiary</th>
                    <th scope="col">Bank</th>
                    <th scope="col">Account Type</th>
                    <th scope="col">Account Holder Name</th>
                    <th scope="col">Account Number</th>
                    <!-- <th scope="col">Payout Frequency</th> -->
                    <th scope="col">Total Amount</th>
                    <th scope="col">Payout Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payout of payouts">
                    <td>
                        <ng-container *ngIf="payout.status === PayoutStatusEnum.PAID">
                            <span class="badge bg-success text-dark">Paid</span>
                        </ng-container>
                        <ng-container *ngIf="payout.status === PayoutStatusEnum.BATCHED">
                            <span class="badge bg-secondary text-light">BATCHED</span>
                        </ng-container>
                    </td>
                    <td>
                        <a [routerLink]="['/admin/payout/', payout.$key]" class="text-decoration-underline"> PFI-{{ payout.payoutNumber }} </a>
                    </td>
                    <td>{{ payout.invoiceRefs.length }}</td>
                    <td>
                        <a [routerLink]="['/merchant', payout.$merchant?.$key]" class="text-decoration-underline">{{ payout.$merchant?.companyName }}</a>
                    </td>
                    <td>{{ payout.$merchant?.payoutDetails?.beneficiaryName }}</td>
                    <td>{{ payout.$merchant?.payoutDetails?.bank }}</td>
                    <td>{{ payout.$merchant?.payoutDetails?.accountType }}</td>
                    <td>{{ payout.$merchant?.payoutDetails?.accountHolderName }}</td>
                    <td>{{ payout.$merchant?.payoutDetails?.accountNumber }}</td>
                    <!-- <td>
                        <span
                            [routerLink]="['/merchant/', payout.$merchant?.$key, 'profile', 'payout']"
                            class="badge"
                            [ngClass]="{
                                ' bg-success': payout.$merchant?.payoutDetails?.payoutFrequency === PayoutFrequencyEnum.IMMEDIATE,
                                ' bg-warning': payout.$merchant?.payoutDetails?.payoutFrequency === PayoutFrequencyEnum.MONTHLY
                            }"
                        >
                            {{ payout.$merchant?.payoutDetails?.payoutFrequency }}
                        </span>
                    </td> -->
                    <td>{{ payout.totalAmount | currency: 'R' }}</td>
                    <td>{{ payout.payoutAmount | currency: 'R' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
