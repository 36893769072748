import { Injectable } from '@angular/core'
import { ContractStatus, FirestoreStructure, Subscription, subscriptionsPath } from '@parkupp/core'
import { addDoc, collection, collectionData, deleteDoc, doc, docData, Firestore, query, serverTimestamp, updateDoc, where } from '@angular/fire/firestore'
import * as firestore from 'firebase/firestore'
import { AuthenticationService } from './authentication.service'
import { map, Observable } from 'rxjs'
import * as moment from 'moment'
@Injectable({
    providedIn: 'root',
})
export class SubscriptionService {
    collection = collection(this.firestore, FirestoreStructure.SUBSCRIPTIONS)
    private formData: any

    constructor(private authSvc: AuthenticationService, public firestore: Firestore) {}

    listForLeads(): Observable<Subscription[]> {
        const sixMonthsAgo = firestore.Timestamp.fromDate(moment().subtract(6, 'months').toDate())
        const statusesArray = [ContractStatus.active, ContractStatus.completed, ContractStatus.new]
        const recentSubsQuery = query(this.collection, where('createdAt', '>=', sixMonthsAgo), where('status', 'in', statusesArray))

        return collectionData(recentSubsQuery, { idField: '$key' }).pipe(map((subscriptions: any[]) => subscriptions.map((subscription: any) => new Subscription(subscription))))
    }

    get(subscriptionKey: string): Observable<any> {
        const clientDocRef = doc(this.firestore, `${FirestoreStructure.SUBSCRIPTIONS}/${subscriptionKey}`)
        return docData(clientDocRef, { idField: '$key' })
    }

    async create(subscription: Subscription) {
        return addDoc(collection(this.firestore, FirestoreStructure.SUBSCRIPTIONS), subscription.parse()).then((docRef) => {
            return { status: 'success', subscriptionReference: docRef.id }
        })
    }

    getSubscription(subscriptionKey: string) {
        return new Promise<any>((resolve, reject) => {
            console.log(subscriptionKey)
            docData(doc(this.firestore, subscriptionsPath(), subscriptionKey)).subscribe((documentData: any) => {
                console.log(documentData)
                let subscription = new Subscription(documentData)
                subscription.$key = subscriptionKey

                // docData(documentData.parkingRef).subscribe((data) => {
                //     subscription.$parking = data as Parking
                //     subscription.$parking.$key = documentData.parkingRef.id
                // })
                // docData(documentData.paymentMethodRef).subscribe((data) => {
                //     subscription.$paymentMethod = data as PaymentMethod;
                //     subscription.$paymentMethod.$key = documentData.paymentMethodRef.id;
                // });
                // docData(documentData.clientRef).subscribe((data) => {
                //     subscription.$client = data as Client;
                //     subscription.$client.$key = documentData.clientRef.id;
                // });
                // if (documentData.occupantRefs) {
                //     subscription.$occupants = [];
                //     documentData.occupantRefs.forEach((occupantsRef: DocumentReference<DocumentData>) => {
                //         docData(occupantsRef).subscribe((data) => {
                //             const occupant = new Occupant(data);
                //             occupant.$key = occupantsRef.id;
                //             subscription.$occupants!.push(occupant);
                //         });
                //
                //     });
                // }
                resolve(subscription)
            })
        })
    }

    sortSubs(subscriptions: Subscription[]) {
        return subscriptions.sort((a, b) => {
            const endDateA = a.endDate.toDate()
            const endDateB = b.endDate.toDate()

            if (endDateA > endDateB) {
                return -1
            }
            if (endDateA < endDateB) {
                return 1
            }
            return 0
        })
    }

    deduplicateSubscriptionsByClientRef(subscriptions: Subscription[]): Subscription[] {
        const deduplicatedSubscriptionsMap = new Map<string, Subscription>()

        subscriptions.forEach((subscription: Subscription) => {
            const clientRefId = subscription.clientRef.id

            if (!deduplicatedSubscriptionsMap.has(clientRefId)) {
                deduplicatedSubscriptionsMap.set(clientRefId, subscription)
            }
        })

        return Array.from(deduplicatedSubscriptionsMap.values())
    }

    async updateSubscription(subscription: Subscription): Promise<void> {
        if (!subscription.$key) {
            throw new Error("Subscription does not have a valid '$key'.")
        }

        // Construct the document reference
        const subscriptionDocRef = doc(this.firestore, subscriptionsPath(), subscription.$key)

        // Optionally set the updatedAt timestamp
        subscription.updatedAt = serverTimestamp() as firestore.Timestamp

        // Perform the update
        await updateDoc(subscriptionDocRef, subscription.parse())
            .then(() => {
                console.log(`Subscription with ID ${subscription.$key} updated successfully.`)
            })
            .catch((error) => {
                console.error('Error updating subscription:', error)
                throw new Error('Failed to update subscription.')
            })
    }

    async delete(key: string): Promise<void> {
        // Construct the document reference
        const subscriptionDocRef = doc(this.firestore, `${FirestoreStructure.SUBSCRIPTIONS}/${key}`)

        // Perform the delete operation
        await deleteDoc(subscriptionDocRef)
            .then(() => {
                console.log(`Subscription with ID ${key} deleted successfully.`)
                return
            })
            .catch((error) => {
                console.error('Error deleting subscription:', error)
                throw new Error('Failed to delete subscription.')
            })
    }
}
