<admin-app-header></admin-app-header>
<div class="container-fluid">
    <div class="row">
        <div class="content col-12">
            <h5 class="px-3">Clients with expired contracts that have not renewed</h5>
            <button class="btn btn-info btn-sm text-white float-end px-3 py-1" (click)="globalService.exportcsv('expired-contracts')"><i class="fas fa-file-csv"></i> Export to CSV</button>
            <table id="expired-contracts" class="table table-striped table-hover ms-2">
                <thead>
                    <tr>
                        <th scope="col">Status</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Time Passed</th>
                        <th scope="col">Client</th>
                        <th scope="col">Parking</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let subscription of expiredSubscriptions">
                        <tr *ngIf="subscription.$client && subscription">
                            <td>
                                <span class="badge" [ngClass]="subscription.statusBadgeColour">
                                    {{ subscription.statusNiceName }}
                                </span>
                            </td>
                            <td>
                                <a [routerLink]="['/admin/subscription/', subscription.$key]">
                                    {{ subscription.endDate | formatDate }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/admin/subscription/', subscription.$key]"> {{ subscription.endDate | daysAgo }} ago </a>
                            </td>
                            <td>
                                <a [routerLink]="['/client/', subscription.$client?.$key]">
                                    {{ subscription.$client?.firstName || 'Unknown' }}
                                    {{ subscription.$client?.lastName || '' }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/parking/', subscription.$parking?.$key]">
                                    {{ subscription.$parking?.title || '' }}
                                </a>
                            </td>
                            <td>
                                <ng-container *ngIf="subscription.$client.$email">
                                    {{ subscription.$client.$email }}
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="subscription.$client.$phoneNumber">
                                    {{ subscription.$client.$phoneNumber }}
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
