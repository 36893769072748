import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { AngularRoutes, ContractStatus, EnumNames, InvoiceStatus, Subscription, subscriptionsPath, SubscriptionType } from '@parkupp/core'
import { collection, collectionData, orderBy, query, where } from '@angular/fire/firestore'
import { GlobalService } from '../../../services/global.service'
import * as moment from 'moment'
import Swal from 'sweetalert2'
import { BackendService } from '../../../services/backend.service'
import { SubscriptionService } from '../../../services/subscription.service'

@Component({
    selector: 'app-admin-subscriptions-page',
    templateUrl: './admin-list-subscriptions-page.component.html',
    styleUrls: ['./admin-list-subscriptions-page.component.scss'],
})
export class AdminListSubscriptionsPageComponent implements OnInit {
    EnumNames = EnumNames
    ContractStatus = ContractStatus
    subscriptions: Subscription[]

    filteredSubscriptions: Subscription[]

    activeContractStatuses: ContractStatus[] = [
        ContractStatus.new,
        ContractStatus.paymentInitiated,
        ContractStatus.active,
        ContractStatus.bouncing,
        ContractStatus.renewing,
        ContractStatus.pendingClientApproval,
    ]
    cancelledContractStatuses: ContractStatus[] = [
        ContractStatus.paymentFailed,
        ContractStatus.clientCancelled,
        ContractStatus.merchantRejected,
        ContractStatus.clientBreachedNonPayment,
        ContractStatus.merchantBreached,
        ContractStatus.mutuallyTerminated,
        ContractStatus.expired,
    ]
    completedContractStatuses: ContractStatus[] = [ContractStatus.completed]
    currentStatusFilter: string = 'active'
    today: number = moment().add(7, 'days').toDate().getTime()
    AngularRoutes = AngularRoutes
    SubscriptionType = SubscriptionType
    selectedSubscription: Subscription

    constructor(public globalService: GlobalService, private subscriptionService: SubscriptionService, private backendService: BackendService, private adminService: AdminService) {}

    ngOnInit(): void {
        this.runSearch()
    }

    runSearch() {
        let statuses: ContractStatus[]
        if (this.currentStatusFilter == 'completed') {
            statuses = this.completedContractStatuses
        } else if (this.currentStatusFilter == 'cancelled') {
            statuses = this.cancelledContractStatuses
        } else {
            statuses = this.activeContractStatuses
        }
        const ref = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('createdAt', 'desc'), where('status', 'in', statuses))
        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.subscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData)
                subscription.$key = documentData.documentId

                this.subscriptions.push(subscription)
            })
            this.filteredSubscriptions = this.subscriptions // Initialize filteredSubscriptions
        })
    }

    changeStatus(e: any) {
        this.currentStatusFilter = e?.target?.value
        this.runSearch()
    }

    searchKeydown($event: any) {
        const input = ($event.target as HTMLInputElement).value.toLowerCase()
        this.filteredSubscriptions = this.subscriptions.filter((subscription) => {
            return (
                subscription.denormMerchantCompanyName?.toLowerCase().includes(input) ||
                subscription.denormClientFirstName?.toLowerCase().includes(input) ||
                subscription.denormClientLastName?.toLowerCase().includes(input) ||
                subscription.denormParkingTitle?.toLowerCase().includes(input)
            )
        })
    }

    async deleteSubscription(subscription: Subscription) {
        const { value: rejectionReason } = await Swal.fire({
            input: 'text',
            title: 'Enter cancellation Reason',
            inputPlaceholder: 'Enter reason',
            showCancelButton: true,
        })

        if (rejectionReason) {
            subscription.status = ContractStatus.clientCancelled
            subscription.rejectionReason = rejectionReason
            await this.subscriptionService.updateSubscription(subscription)

            // Send the input to the backend service
            await this.backendService.httpSendSlack({
                message: `*Subscription Cancelled by Admin* - ${subscription.denormClientFirstName} ${subscription.denormClientLastName} - ${rejectionReason}`,
            })
        }
    }

    async showContextMenu(event: MouseEvent, subscription: Subscription) {
        this.globalService.contextMenuItems = []
        this.selectedSubscription = subscription
        this.globalService.showContextMenu(event)
        // this.globalService.contextMenuItems.push({
        //     icon: 'fa fa-credit-card',
        //     label: 'Set Type to PayGate',
        //     action: () => {
        //         this.selectedSubscription.type = SubscriptionType.PAYGATE
        //         this.subscriptionService.updateSubscription(this.selectedSubscription)
        //     },
        // })
        // this.globalService.contextMenuItems.push({
        //     icon: 'fa fa-file-invoice',
        //     label: 'Set Type to PayFast',
        //     action: () => {
        //         this.selectedSubscription.type = SubscriptionType.PAYFAST
        //         this.subscriptionService.updateSubscription(this.selectedSubscription)
        //     },
        // })
        // this.globalService.contextMenuItems.push({
        //     icon: 'fa fa-bank',
        //     label: 'Set Type to EFT',
        //     action: () => {
        //         this.selectedSubscription.type = SubscriptionType.EFT
        //         this.subscriptionService.updateSubscription(this.selectedSubscription)
        //     },
        // })
        this.globalService.contextMenuItems.push({
            icon: 'fa fa-trash',
            label: 'Delete Subscription',
            action: async () => {
                await this.deleteSubscription(this.selectedSubscription)
            },
        })
        if (this.selectedSubscription.type == SubscriptionType.EFT) {
            this.globalService.contextMenuItems.push({
                icon: 'fa fa-edit',
                label: 'Edit EFT Details',
                action: () => {
                    window.location.href = `/admin/subscriptions-manual/edit/${this.selectedSubscription.$key}`
                },
            })
        }
        // this.globalService.contextMenuItems.push({
        //     icon: 'fa fa-calendar',
        //     label: 'Set Next Debit Date',
        //     action: async () => {
        //         const { value: date } = await Swal.fire({
        //             title: 'Enter next debit date',
        //             input: 'text',
        //             inputLabel: 'Next Debit Date',
        //             inputPlaceholder: 'YYYY-MM-DD',
        //             showCancelButton: true,
        //             inputValidator: (value) => {
        //                 if (!value) {
        //                     return 'You need to write something!'
        //                 }
        //                 // Simple date validation
        //                 const datePattern = /^\d{4}-\d{2}-\d{2}$/
        //                 if (!datePattern.test(value)) {
        //                     return 'Invalid date format. Please use YYYY-MM-DD.'
        //                 }
        //                 return null
        //             },
        //         })

        //         if (date) {
        //             this.selectedSubscription.nextDebitDate = date
        //             await this.subscriptionService.updateSubscription(this.selectedSubscription)
        //             Swal.fire(`Next debit date set to: ${date}`)
        //         }
        //     },
        // })
    }
}
