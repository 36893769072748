<div class="container mt-5">
    <div class="row">
        <div class="col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <h1 *ngIf="!showAsRegistration" class="mb-1">Log In</h1>
            <h1 *ngIf="showAsRegistration" class="mb-4">Create your profile</h1>

            <div *ngIf="this.globalService.whitelabeled">
                <h5>Powered by <img src="/assets/images/branding/logo-blue.svg" class="powered-by" /></h5>
            </div>

            <div *ngIf="!showAsRegistration" class="mb-4">
                Don't have a profile?
                <a [routerLink]="'/sign-up'" class="text-primary text-decoration-underline"> Click here to register </a>
            </div>
            <p *ngIf="showAsRegistration">
                Once you have your profile on ParkUpp you can start parking or list your parking spot. Already have a profile?
                <a [routerLink]="'/sign-in'" class="text-primary text-decoration-underline"> Click here to login </a>
            </p>

            <div *ngIf="!withPassword" class="d-grid">
                <button type="button" class="btn btn-primary btn-email d-block mb-3 rounded" (click)="setSignUpWithPassword(true)">Login using Email Address</button>
                <button type="button" class="btn btn-primary btn-google mb-3 rounded" (click)="signUpWithGoogle()">Login With Google</button>
                <button type="button" class="btn btn-primary btn-facebook d-block mb-3 rounded" (click)="signUpWithFacebook()">Login With Facebook</button>
            </div>
            <div *ngIf="withPassword">
                <button type="button" class="btn btn-primary mb-3 rounded" (click)="setSignUpWithPassword(false)">Back to social login</button>
                <form [formGroup]="formGroup" (ngSubmit)="signUpWithPassword()">
                    <div class="form-group mb-3">
                        <label>Email</label>
                        <input type="email" name="email" id="email" formControlName="email" placeholder="Email" class="form-control" />
                        <div invalidMessage="email" class="invalid-feedback">{{ formGroup.get('email') | validationErrors }}</div>
                    </div>
                    <div class="form-group mb-3">
                        <label>Password</label>
                        <input type="password" name="password" id="password" formControlName="password" placeholder="Password" class="form-control" />
                        <div invalidMessage="password" class="invalid-feedback">{{ formGroup.get('password') | validationErrors }}</div>
                        <a (click)="forgotPassword()" class="text-primary text-decoration-underline"><small>Click here to reset your password</small></a>
                    </div>
                    <button type="submit" [disabled]="loading" class="btn btn-primary"><i *ngIf="loading" class="fa fa-circle-notch fa-spin"></i><span *ngIf="!loading">Continue</span></button>
                </form>
            </div>
            <div *ngIf="error" class="alert alert-warning">
                {{ error }}
            </div>
            <div *ngIf="!valid && formGroup.invalid" class="alert alert-info">Please complete required fields</div>
            <p>
                <small>
                    I agree to ParkUpp's
                    <a target="_blank" [routerLink]="['/terms-and-conditions']">Terms and Conditions</a> and <a target="_blank" [routerLink]="['/privacy']">Privacy Policy</a> and to receive email
                    communications from ParkUpp.
                </small>
            </p>
        </div>
    </div>
</div>
