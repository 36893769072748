import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { collection, collectionData, orderBy, query } from '@angular/fire/firestore'
import { Payment, paymentsPath } from '@parkupp/core'
import { HttpClient } from '@angular/common/http'

@Component({
    selector: 'app-admin-list-payments-page',
    templateUrl: './admin-list-payments-page.component.html',
    styleUrls: ['./admin-list-payments-page.component.scss'],
})
export class AdminListPaymentsPageComponent implements OnInit {
    payments: Payment[]

    constructor(private adminSvc: AdminService, private http: HttpClient) {
        const ref = query(collection(this.adminSvc.firestore, paymentsPath()), orderBy('createdAt', 'desc'))

        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            let payments: Payment[] = []
            documents.forEach((documentData) => {
                let payment = new Payment(documentData)
                payment.$key = documentData.documentId

                // if (documentData.clientRef) {
                //     docData(documentData.clientRef).subscribe((data) => {
                //         payment.$client = new Client(data)
                //         payment.$client.$key = documentData.clientRef.id
                //     })
                // }
                // if (documentData.subscriptionRef) {
                //     docData(documentData.subscriptionRef).subscribe((data) => {
                //         payment.$subscription = new Subscription(data)
                //         payment.$subscription.$key = documentData.subscriptionRef.id
                //     })
                // }
                // if (documentData.parkingRef) {
                //     docData(documentData.parkingRef).subscribe((data) => {
                //         payment.$parking = new Parking(data)
                //         payment.$parking.$key = documentData.parkingRef.id
                //     })
                // }
                // if (documentData.merchantRef) {
                //     docData(documentData.merchantRef).subscribe((data) => {
                //         payment.$merchant = new Merchant(data)
                //         payment.$merchant.$key = documentData.merchantRef.id
                //     })
                // }
                // if (documentData.settlementRefs) {
                //     docData(documentData.merchantRef, { idField: 'documentKey' }).subscribe((data) => {
                //         payment.$merchant = data as Merchant
                //         payment.$merchant.$key = data['documentKey']
                //     })
                // }

                payments.push(payment)
            })
            this.payments = payments
        })
    }

    ngOnInit(): void {}
}
