import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import {
    Client,
    clientsPrivatePath,
    ContractStatus,
    FirestoreStructure,
    Invoice,
    InvoiceStatus,
    Merchant,
    Parking,
    ParkingStatus,
    Subscription,
    SubscriptionRenewalStatus,
    subscriptionsPath,
    Term,
} from '@parkupp/core'
import { collection, collectionData, docData, orderBy, query, where } from '@angular/fire/firestore'
import * as moment from 'moment'
import { Moment } from 'moment'
import { ParkingService } from '../../../services/parking.service'
import { firstValueFrom, take } from 'rxjs'
import { InvoiceService } from 'src/app/services/invoice.service'

@Component({
    selector: 'app-admin-dashboard-page',
    templateUrl: './admin-dashboard-page.component.html',
    styleUrls: ['./admin-dashboard-page.component.scss'],
})
export class AdminDashboardPageComponent implements OnInit {
    chartSalesData: any
    chartSalesColorScheme: any = { domain: [] }
    chartMonthlyData: any
    chartMonthlyColorScheme: any = { domain: [] }
    chartBaysData: any
    chartBaysColorScheme: any = { domain: [] }
    subscriptions: Subscription[]
    inactiveSubscriptions: Subscription[]
    activeSubscriptions: number
    dates: { start: Moment; end: Moment }[]

    fullContractValueLoaded: Boolean = false
    baysLoaded: boolean = false
    monthlyTurnOverLoaded: Boolean = false
    totalParkings: number = 0
    totalBuildings: number = 0
    expiringSubscriptions: Subscription[] = []
    subscriptionRenewalStatus = Object.keys(SubscriptionRenewalStatus)
    overdueInvoices: Number
    users: number
    invoicesNotPaidOut: Invoice[]
    totalUnpaidAmount: number
    overdueInvoicesTotal: number
    unpaidInvoices: number
    totalUnpublishedBuildings: number
    invoicesWithoutBankDetails: Invoice[] = []
    invoicesWithoutBankDetailsTotalValue: number = 0
    noBankMerchantRefs: any = []
    totalContractValue: number = 0

    constructor(private adminService: AdminService, private parkingService: ParkingService, private invoiceService: InvoiceService) {
        const inactiveContractStatuses = [ContractStatus.active, ContractStatus.bouncing]

        // get total parkings
        this.parkingService.list().subscribe((parkings: Parking[]) => {
            this.totalBuildings = 0
            this.totalParkings = 0
            this.totalUnpublishedBuildings = 0

            parkings.forEach((parking) => {
                if (parking.numberOfBays && parking.parkingStatus == ParkingStatus.PUBLISHED) {
                    this.totalParkings = this.totalParkings + Number(parking.numberOfBays)
                    this.totalBuildings = this.totalBuildings + 1
                } else {
                    this.totalUnpublishedBuildings = this.totalUnpublishedBuildings + 1
                }
            })
        })

        const inactiveRef = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('createdAt', 'desc'), where('status', 'in', [ContractStatus.paymentInitiated]))
        collectionData(inactiveRef, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.inactiveSubscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData)
                subscription.$key = documentData.documentId

                docData(documentData.parkingRef).subscribe((data) => {
                    subscription.$parking = new Parking(data)
                    subscription.$parking.$key = documentData.parkingRef.id
                })
                docData(documentData.clientRef).subscribe((data) => {
                    subscription.$client = new Client(data)
                    subscription.$client.$key = documentData.clientRef.id
                })
                docData(documentData.merchantRef).subscribe((data) => {
                    subscription.$merchant = new Merchant(data)
                    subscription.$merchant.$key = documentData.merchantRef.id
                })
                this.inactiveSubscriptions.push(subscription)
            })
        })

        const usersRef = collection(this.adminService.firestore, 'clients')
        collectionData(usersRef, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.users = documents.filter((doc) => doc.firstName).length
        })

        // Stuck renewing
        this.invoiceService.listOverdueInvoices().subscribe((invoices) => {
            this.overdueInvoicesTotal = 0
            this.overdueInvoices = invoices.length
            invoices.forEach((invoiceData) => {
                const invoice = new Invoice(invoiceData)
                this.overdueInvoicesTotal += invoice.amountTotalDue
            })
        })
        // Stuck renewing
        this.invoiceService.listInvoicesNotPaidOut().subscribe((invoices) => {
            this.invoicesNotPaidOut = invoices
            this.totalUnpaidAmount = 0
            this.unpaidInvoices = invoices.length

            console.log(this.invoicesNotPaidOut)
            this.invoicesNotPaidOut.forEach((invoiceData) => {
                const invoice = new Invoice(invoiceData)
                this.totalUnpaidAmount += invoice.periodFinalPayout
            })
        })

        // Get active count
        const activeRef = query(collection(this.adminService.firestore, subscriptionsPath()), where('status', 'in', [ContractStatus.active]))
        collectionData(activeRef, { idField: 'documentId' })
            .pipe(take(1))
            .subscribe((documents: any[]) => {
                this.activeSubscriptions = 0
                documents.forEach((documentData) => {
                    let subscription = new Subscription(documentData)
                    subscription.$key = documentData.documentId

                    if (subscription.plan.term == Term.MONTHLY) {
                        this.activeSubscriptions = this.activeSubscriptions + subscription.baysBooked
                    }

                    const monthsLeft = moment(subscription.endDate.toDate()).diff(moment(), 'months')
                    const monthlyFee = subscription.periodFee
                    const totalContractValue = monthlyFee * monthsLeft

                    console.log(subscription.$key, monthsLeft, totalContractValue)

                    this.totalContractValue = this.totalContractValue + totalContractValue
                })
            })

        // Get Expiring Soon
        const expiringRef = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('endDate'), where('status', 'in', [ContractStatus.active]))
        collectionData(expiringRef, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.expiringSubscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData)
                subscription.$key = documentData.documentId

                if (documentData.clientRef) {
                    docData(documentData.clientRef).subscribe((data) => {
                        subscription.$client = new Client(data)
                        subscription.$client.$key = documentData.clientRef.id
                    })
                }

                if (documentData.parkingRef) {
                    docData(documentData.parkingRef).subscribe((data) => {
                        subscription.$parking = new Parking(data)
                        subscription.$parking.$key = documentData.parkingRef.id
                    })
                }

                if (subscription.endDate) {
                    const expiryDate = moment(subscription.endDate.toDate().toISOString())
                    const tenDaysFromNow = moment().add(12, 'days')

                    if (expiryDate.isSameOrBefore(tenDaysFromNow, 'day') && expiryDate.isSameOrAfter(moment(), 'day') && !subscription.nextDebitDate) {
                        this.expiringSubscriptions.push(subscription)
                    }
                }
            })

            this.findAndAppendClientPrivate()
        })

        const ref = query(collection(this.adminService.firestore, subscriptionsPath()), where('status', 'in', inactiveContractStatuses))
        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.subscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData)
                subscription.$key = documentData.documentId
                this.subscriptions.push(subscription)
            })

            this.loadMonthlyTurnOver()

            this.loadBays()

            this.loadContractRevenue()
        })

        const invoicesRef = query(collection(this.adminService.firestore, FirestoreStructure.INVOICES), where('status', '==', InvoiceStatus.PAID), where('paidOut', '==', false))
        collectionData(invoicesRef, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.invoicesWithoutBankDetails = []
            documents.forEach((documentData) => {
                let invoice: Invoice = new Invoice(documentData)
                invoice.$key = documentData.documentId

                if (documentData.merchantRef) {
                    docData(documentData.merchantRef).subscribe((data) => {
                        invoice.$merchant = new Merchant(data)
                        invoice.$merchant.$key = documentData.merchantRef.id

                        if (!invoice.$merchant.payoutDetails || !invoice.$merchant.payoutDetails.accountNumber) {
                            this.invoicesWithoutBankDetails.push(invoice)

                            if (!this.noBankMerchantRefs.some((ref: any) => ref.denormMerchantCompanyName === invoice.denormMerchantCompanyName)) {
                                this.noBankMerchantRefs.push({
                                    id: documentData.merchantRef.id,
                                    denormMerchantCompanyName: invoice.denormMerchantCompanyName,
                                })
                            }
                            this.invoicesWithoutBankDetailsTotalValue += invoice.periodFinalPayout
                        }
                    })
                }
            })
        })

        // this.colorScheme = {
        //     domain: [
        //         '#ac9aff', // Draft
        //         '#6796ff', // Recurring
        //         '#dbdbdb', // amountPaid
        //         '#8affb7', // Amount due
        //         '#e3b649', // Quotes accepted
        //         '#ffecbf', // Quotes sent
        //         '#ffabb9', // Timelogs
        //     ],
        // }
    }

    async findAndAppendClientPrivate() {
        const clientsPrivateRef = query(collection(this.adminService.firestore, clientsPrivatePath()))

        const clientsPrivateData = await firstValueFrom(collectionData(clientsPrivateRef, { idField: 'documentId' }))

        if (clientsPrivateData) {
            const clientsPrivateMap = new Map(clientsPrivateData.map((clientPrivate: any) => [clientPrivate.documentId, clientPrivate]))

            this.expiringSubscriptions.forEach((subscription) => {
                if (subscription.$client) {
                    // Add this check to prevent accessing properties of undefined
                    const clientPrivate = clientsPrivateMap.get(subscription.$client.$key)

                    if (clientPrivate) {
                        const updatedClient = {
                            ...subscription.$client,
                            $email: clientPrivate.email, // Only include the email property
                            $phoneNumber: clientPrivate.phoneNumber, // Only include the phoneNumber property
                        }

                        subscription.$client = Object.assign(new Client(), updatedClient)
                    }
                }
            })
        }
    }

    loadMonthlyTurnOver() {
        if (!this.monthlyTurnOverLoaded) {
            // ---------------------------------------------------
            // monthly
            // ---------------------------------------------------
            this.chartMonthlyColorScheme = {
                domain: ['#ac9aff'],
            }
            this.chartMonthlyData = []
            this.dates = []

            this.dates.push({
                start: moment().startOf('month'),
                end: moment().endOf('month'),
            })
            for (let i = 1; i < 12; i++) {
                this.dates.push({
                    start: moment().add(i, 'months').startOf('month'),
                    end: moment().add(i, 'months').endOf('month'),
                })
            }

            this.dates.forEach((chartDate) => {
                let total = 0
                const monthStartDate = chartDate.start
                const monthEndDate = chartDate.end

                this.subscriptions.forEach((subscription: Subscription) => {
                    // if (subscription.endDate.toDate() > date) {
                    // if (moment(subscription.endDate.toDate()).format() < moment(date.end).format() && moment(subscription.startDate.toDate()).format() > moment(date.start).format()) {
                    //     total = total + subscription.plan.feeInCents / 100
                    // }

                    const subStartDate = moment(subscription.startDate.toDate())
                    const subEndDate = moment(subscription.endDate.toDate())

                    let include = false
                    if (monthStartDate.add(1, 'day').isSameOrAfter(subStartDate)) {
                        include = true
                    }
                    if (subEndDate.isBefore(monthStartDate)) {
                        include = false
                    }
                    if (include) {
                        total = total + (subscription.plan.feeInCents * subscription.baysBooked) / 100
                    }
                })

                this.chartMonthlyData.push({
                    name: moment(chartDate.start).format('MMM-YY'),
                    series: [
                        {
                            name: 'Total',
                            value: total,
                        },
                    ],
                })
            })
        }
    }

    loadBays() {
        if (!this.baysLoaded) {
            // ---------------------------------------------------
            // Bays
            // ---------------------------------------------------
            this.chartBaysColorScheme = {
                domain: ['#6796ff'],
            }
            this.chartBaysData = []
            this.dates = []

            this.dates.push({
                start: moment().startOf('month'),
                end: moment().endOf('month'),
            })
            for (let i = 0; i < 12; i++) {
                // this.dates.push(moment().endOf('month').add(i, 'months'))
                this.dates.push({
                    start: moment().add(i, 'months').startOf('month'),
                    end: moment().add(i, 'months').endOf('month'),
                })
            }

            this.dates.forEach((chartDate) => {
                let bays = 0
                const monthStartDate = chartDate.start
                const monthEndDate = chartDate.end

                this.subscriptions.forEach((subscription: Subscription) => {
                    // if (moment(subscription.endDate.toDate()).format() < moment(date.end).format() && moment(subscription.startDate.toDate()).format() > moment(date.start).format()) {
                    // if (moment(subscription.startDate.toDate()).format() > moment(date.start).format() && !(moment(subscription.endDate.toDate()).format() < moment(date.end).format())) {
                    const subStartDate = moment(subscription.startDate.toDate())
                    const subEndDate = moment(subscription.endDate.toDate())

                    let include = false
                    // if (chartDate.start.isSameOrAfter(subStartDate) && !chartDate.end.isAfter(subEndDate)) {
                    if (monthStartDate.add(1, 'day').isSameOrAfter(subStartDate)) {
                        include = true
                    }
                    if (subEndDate.isBefore(monthStartDate)) {
                        include = false
                    }
                    if (include) {
                        bays = bays + subscription.baysBooked
                    }
                })

                this.chartBaysData.push({
                    name: moment(chartDate.start).format('MMM-YY'),
                    series: [
                        {
                            name: 'Bays',
                            value: bays,
                        },
                    ],
                })
            })
            this.baysLoaded = true
        }
    }

    loadContractRevenue() {
        if (!this.fullContractValueLoaded) {
            // ---------------------------------------------------
            // Monthly Sales
            // ---------------------------------------------------
            this.chartSalesColorScheme = {
                domain: ['#8affb7'],
            }
            this.chartSalesData = []
            this.dates = []

            for (let i = 11; i > 0; i--) {
                // this.dates.push(moment().endOf('month').subtract(i, 'months').toDate())
                this.dates.push({
                    start: moment().subtract(i, 'months').startOf('month'),
                    end: moment().subtract(i, 'months').endOf('month'),
                })
            }

            this.dates.push({
                start: moment().startOf('month'),
                end: moment().endOf('month'),
            })

            for (let i = 1; i <= 3; i++) {
                this.dates.push({
                    start: moment().add(i, 'months').startOf('month'),
                    end: moment().add(i, 'months').endOf('month'),
                })
            }

            this.dates.forEach((date) => {
                let total = 0

                this.subscriptions.forEach((subscription: Subscription) => {
                    //     subDate: moment(subscription.startDate.toDate()).format('MMM-YY'),
                    // })
                    if (moment(subscription.createdAt.toDate()).format() < moment(date.end).format() && moment(subscription.createdAt.toDate()).format() > moment(date.start).format()) {
                        total = total + subscription.totalContractValue
                    }
                })

                this.chartSalesData.push({
                    name: moment(date.end).format('MMM-YY'),
                    series: [
                        {
                            name: 'Total',
                            value: Math.round(total / 100),
                        },
                    ],
                })
            })
        }
    }

    // TODO: DYLAN: Need admin auth guards here
    ngOnInit(): void {}
}
