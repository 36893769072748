import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AngularRoutes, Invoice, InvoiceStatus, PayoutFrequencyEnum } from '@parkupp/core'
import { Payout } from '@parkupp/core/src/models/invoicing/payout.model'
import { InvoiceService } from 'src/app/services/invoice.service'
import { MerchantService } from 'src/app/services/merchant.service'
import { PayoutService } from 'src/app/services/payout.service'

@Component({
    selector: 'app-admin-payout-details',
    templateUrl: './admin-payout-details.component.html',
    styleUrls: ['./admin-payout-details.component.scss'],
})
export class AdminPayoutDetailsComponent implements OnInit {
    PayoutFrequencyEnum = PayoutFrequencyEnum
    InvoiceStatus = InvoiceStatus
    AngularRoutes = AngularRoutes
    payout: Payout
    payoutKey: string
    invoices: Invoice[] = []

    constructor(private payoutService: PayoutService, private route: ActivatedRoute, private invoiceService: InvoiceService, private merchantService: MerchantService) {}

    async ngOnInit() {
        this.payoutKey = this.route.snapshot.params['payoutKey']
        this.payout = await this.payoutService.getPayout(this.payoutKey)

        const payoutRef = this.payoutService.getDocRef(this.payoutKey)
        this.invoices = await this.invoiceService.getInvoicesByPayout(payoutRef)

        this.invoices.forEach(async (invoice) => {
            if (invoice.merchantRef) {
                invoice.$merchant = await this.merchantService.getMerchant(invoice.merchantRef.id)
            }
        })
        console.log(this.invoices)
        console.log(this.payout)
    }
}
