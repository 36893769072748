import { Injectable } from '@angular/core'
import { SubscriptionService } from './subscription.service'
import { FirestoreStructure, Image, merchantParkingImagesPath, merchantParkingsPath, Parking, parkingsPath, StorageStructure } from '@parkupp/core'
import { addDoc, collection, collectionData, doc, docData, Firestore, query, serverTimestamp, updateDoc } from '@angular/fire/firestore'
import * as firestore from 'firebase/firestore'
import { getDownloadURL, ref, Storage, uploadBytes } from '@angular/fire/storage'
import { UploadResult } from '@firebase/storage'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ParkingService {
    collection = collection(this.firestore, FirestoreStructure.PARKINGS)

    constructor(public firestore: Firestore, private subscriptionSvc: SubscriptionService, private storage: Storage) {}

    getDocRef(parkingKey: string) {
        return doc(this.firestore, `${FirestoreStructure.PARKINGS}/${parkingKey}`)
    }

    get(parkingKey: string): Observable<any> {
        const parkingDocRef = doc(this.firestore, `${FirestoreStructure.PARKINGS}/${parkingKey}`)
        return docData(parkingDocRef, { idField: '$key' })
    }

    getStatic(parkingKey: string) {
        return new Promise<any>((resolve, reject) => {
            docData(doc(this.firestore, parkingsPath(), parkingKey)).subscribe((documentData: any) => {
                let parking = new Parking(documentData)
                parking.$key = parkingKey
                resolve(parking)
            })
        })
    }

    list(): Observable<Parking[]> {
        const invoicesQuery = query(this.collection)
        return collectionData(invoicesQuery, { idField: '$key' }) as Observable<Parking[]>
    }

    addMerchantParking(parking: Parking, uid: string) {
        const reference = collection(this.firestore, merchantParkingsPath(uid))
        parking.createdAt = serverTimestamp() as firestore.Timestamp
        parking.updatedAt = serverTimestamp() as firestore.Timestamp
        console.log('adding')
        console.log(parking.parse())
        return addDoc(reference, parking.parse())
    }

    updateMerchantParking(fields: { [id: string]: any }, uid: string, docId: string) {
        fields['updatedAt'] = serverTimestamp() as firestore.Timestamp
        return updateDoc(doc(this.firestore, merchantParkingsPath(uid), docId), fields)
    }

    subscribeToParking(merchantKey: string, parkingKey: string) {
        return docData(doc(this.firestore, merchantParkingsPath(merchantKey), parkingKey))
    }

    deleteParkingImage(merchantKey: string, parkingKey: string, imageKey: string, images: { [id: string]: Image }) {
        delete images[`${imageKey}`]
        return updateDoc(doc(this.firestore, merchantParkingImagesPath(merchantKey, parkingKey)), { images: images })
    }

    uploadServiceAgreement(uid: string, parkingKey: string, serviceAgreement: any): Promise<UploadResult> {
        return uploadBytes(ref(this.storage, `${StorageStructure.SERVICE_AGREEMENTS}/${uid}/${parkingKey}/${parkingKey}`), serviceAgreement)
    }

    getServiceAgreementUrl(uid: string, parkingKey: string, serviceAgreementName: string): Promise<any> {
        return getDownloadURL(ref(this.storage, `${StorageStructure.SERVICE_AGREEMENTS}/${uid}/${parkingKey}/${serviceAgreementName}`))
    }
}
