import { Injectable } from '@angular/core'
import { Functions, httpsCallable } from '@angular/fire/functions'
import { SlackChannel } from '@parkupp/core'

@Injectable({
    providedIn: 'root',
})
export class BackendService {
    constructor(private functions: Functions) {}

    async httpVoidRequest(paymentKey: string): Promise<any> {
        try {
            const callable = httpsCallable(this.functions, 'payGate-httpVoidRequest')
            const result = await callable({ paymentKey }) // Invoke the function and await the Promise
            return result.data // Assuming the data you want is stored in the `data` property
        } catch (error) {
            console.error('Error when calling void payment function:', error)
            throw new Error('Failed to invoke void payment function')
        }
    }

    async httpSendSlack(post: { message: string; channel?: SlackChannel }): Promise<any> {
        try {
            const callable = httpsCallable(this.functions, 'httpSendSlack')
            const result = await callable(post) // Invoke the function and await the Promise
            return result.data // Assuming the data you want is stored in the `data` property
        } catch (error) {
            console.error('Error when calling void payment function:', error)
            throw new Error('Failed to invoke void payment function')
        }
    }

    async httpCreateException(post: { message: string; json: string | object }): Promise<any> {
        try {
            // Check if 'json' is an object and stringify it if necessary
            if (typeof post.json === 'object') {
                post = {
                    ...post, // Spread the existing properties
                    json: JSON.stringify(post.json), // Convert 'json' to a string
                }
            }

            const callable = httpsCallable(this.functions, 'httpCreateException')
            const result = await callable(post) // Invoke the function and await the Promise
            return result.data // Assuming the data you want is stored in the `data` property
        } catch (error) {
            console.error('Error when calling void payment function:', error)
        }
    }
}
