<admin-app-header></admin-app-header>
<ng-container *ngIf="!isAdminCreateMerchant">
    <div class="container-fluid">
        <button (click)="createMerchant()" class="btn btn-primary btn-sm float-end m-3" type="button">Create Portfolio</button>

        <h1>Portfolios</h1>

        <div class="content col-lg-12">
            <table class="table table-striped table-hover table-sm">
                <thead>
                <tr>
                    <th scope="col">Created</th>
                    <th scope="col">Type</th>
                    <th scope="col">Fee</th>
                    <th scope="col">Name</th>
                    <th scope="col">Parkings</th>
                    <th scope="col">Bank Accounts</th>
                    <th scope="col">Company Registration</th>
                    <th scope="col">Vat Number</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Tools</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let merchant of merchants">
                    <td>
                        {{ merchant.createdAt | daysAgo }}
                    </td>
                    <td>
                        <span *ngIf="merchant.bookuppEnabled" class="badge bg-info"> ReserveUpp </span>
                        <span *ngIf="!merchant.bookuppEnabled" class="badge bg-primary text-black"> ParkUpp </span>
                    </td>
                    <td>
                        <span *ngIf="merchant.commissionPercentage === 0" class="badge bg-danger"> Exception {{ merchant.commissionPercentage }}% </span>
                        <span *ngIf="merchant.commissionPercentage" class="badge bg-warning"> Exception {{ merchant.commissionPercentage }}% </span>
                        <span *ngIf="!merchant.commissionPercentage && merchant.commissionPercentage !== 0" class="badge bg-secondary"> 15% </span>
                    </td>
                    <td>
                        <a [routerLink]="['/merchant/', merchant.$key]">
                            {{ merchant.companyName || '' }}
                            <span *ngIf="!merchant.companyName" class="undefined">{{ merchant.$key }}</span>
                        </a>
                    </td>

                    <td>
                        <span *ngIf="merchant.$parkings && merchant.$parkings?.length != 0" class="badge bg-secondary"> {{ merchant.$parkings?.length }} Parkings </span>
                    </td>
                    <td>
                        <span *ngIf="merchant.$bankDetails && merchant.$bankDetails?.length != 0" class="badge bg-secondary"> {{ merchant.$bankDetails?.length }} Banks </span>
                    </td>
                    <td>
                        {{ merchant.companyRegistration }}
                    </td>
                    <td>
                        {{ merchant.vatNumber }}
                    </td>
                    <td>
                        {{ merchant.phone?.nationalNumber }}
                    </td>
                    <td>
                        <a (click)="deleteMerchant(merchant)" class="badge bg-danger text-white">Delete</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isAdminCreateMerchant">
    <app-merchant-profile-form [isAdminCreateMerchant]="isAdminCreateMerchant"></app-merchant-profile-form>
</ng-container>
