import { Component, OnInit } from '@angular/core'
import { AngularRoutes, ContractStatus, ExceptionAlert, Subscription } from '@parkupp/core'
import { DocumentData, Query } from '@angular/fire/firestore'
import { GlobalService } from '../../../services/global.service'
import { ActivatedRoute, Router } from '@angular/router'
import { AdminService } from '../../../services/admin.service'
import { ExceptionAlertService } from '../../../services/exception-alert.service'

@Component({
    selector: 'app-admin-list-exceptions',
    templateUrl: './admin-list-exceptions.component.html',
    styleUrls: ['./admin-list-exceptions.component.scss'],
})
export class AdminListExceptionsComponent implements OnInit {
    subscriptions: Subscription[]
    inactiveContractStatuses: ContractStatus[]
    ref: Query<DocumentData>
    status: string
    // exceptionAlerts$: Observable<ExceptionAlert[]>
    readonly AngularRoutes = AngularRoutes
    exceptionAlerts: ExceptionAlert[] = []
    lastVisible: any
    showLoadMoreButton: boolean = true

    constructor(public globalService: GlobalService, private route: ActivatedRoute, private router: Router, private adminService: AdminService, private exceptionAlertService: ExceptionAlertService) {}

    ngOnInit() {
        this.loadMore()
    }

    loadMore() {
        this.exceptionAlertService.list(this.lastVisible).subscribe((data) => {
            console.log('Fetched exceptions:', data)
            if (data.length > 0) {
                const newExceptionAlerts = data.map((doc) => {
                    const exceptionAlert = doc.data() as ExceptionAlert
                    exceptionAlert.$key = doc.id
                    return exceptionAlert
                }) // Cast each document's data to the ExceptionAlert type
                this.exceptionAlerts = [...this.exceptionAlerts, ...newExceptionAlerts]
                this.lastVisible = data[data.length - 1] // Set the last document snapshot
                console.log('New lastVisible set:', this.lastVisible.data())
            } else {
                this.showLoadMoreButton = false
            }
        })
    }
}
