<admin-app-header></admin-app-header>
<div class="container-fluid admin-page">
    <div class="row header">
        <div class="col-2">
            <h2 class="">Subscriptions</h2>
        </div>

        <div class="col-2">
            <div class="form-group m-2">
                <input (input)="searchKeydown($event)" class="form-control" placeholder="Search" />
            </div>
        </div>
        <div class="col-2">
            <div class="form-group m-2">
                <select (change)="changeStatus($event)" aria-label="Status select" class="form-control form-select form-select-sm">
                    <option [selected]="this.currentStatusFilter == 'active'" value="active">Active</option>
                    <option [selected]="this.currentStatusFilter == 'cancelled'" value="cancelled">Cancelled</option>
                    <option [selected]="this.currentStatusFilter == 'completed'" value="completed">Completed</option>
                </select>
            </div>
        </div>
        <div class="col-6">
            <a [routerLink]="['/admin/subscriptions/create']" class="btn btn-sm bg-payfast text-light d-inline-block py-1 ms-3 mt-1 d-inline-block"> Create PayFast Subscription </a>
            <a [routerLink]="['/admin/subscriptions-manual/create']" class="btn btn-sm bg-secondary text-light d-inline-block py-1 ms-3 mt-1 d-inline-block"> Create Manual Subscription </a>
            <button (click)="globalService.exportcsv('all-subscriptions')" class="d-inline-block btn btn-info btn-sm text-white float-end px-3 py-1 my-2">
                <i class="fas fa-file-csv"></i> Export to CSV
            </button>
        </div>
    </div>

    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm" id="all-subscriptions">
            <thead>
                <tr>
                    <th scope="col">Created</th>
                    <th scope="col">Status</th>
                    <th scope="col">Old<br />Type</th>
                    <th scope="col">New<br />Type</th>
                    <th scope="col">Client</th>
                    <th scope="col">Portfolio</th>
                    <th scope="col">Parking</th>
                    <th scope="col">Bays</th>
                    <th scope="col">Start</th>
                    <th scope="col">End</th>
                    <th scope="col">Duration</th>
                    <!--                <th scope="col">Fee</th>-->
                    <!--                <th scope="col">Length</th>-->
                    <th scope="col">Monthly Fee</th>
                    <!--                <th scope="col">Payout</th>-->
                    <!--                <th scope="col">Comm</th>-->
                    <th scope="col">Invoice <br />Due Date</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let subscription of filteredSubscriptions">
                    <tr (contextmenu)="showContextMenu($event, subscription)" [ngClass]="{ selected: selectedSubscription === subscription }">
                        <td>
                            <a [routerLink]="['/admin/subscription', subscription.$key]">
                                {{ subscription.createdAt | formatDate }}
                            </a>
                        </td>

                        <!-- Status -->
                        <td>
                            <span [ngClass]="subscription.statusBadgeColour" class="badge">
                                {{ subscription.statusNiceName }}
                            </span>
                            <ng-container *ngIf="subscription.attempts">
                                -
                                <span class="badge bg-danger">
                                    {{ subscription.attempts }}
                                </span>
                            </ng-container>
                        </td>

                        <!-- Method -->
                        <td>
                            <span *ngIf="subscription.paymentMethodRef || subscription.status == ContractStatus.new; else otherType" class="badge bg-paygate"> PayGate </span>
                            <ng-template #otherType>
                                <ng-container *ngIf="!subscription.paymentMethodRef">
                                    <span *ngIf="subscription.type == SubscriptionType.billable" class="badge bg-payfast"> PayFast </span>

                                    <ng-container *ngIf="subscription.type == SubscriptionType.manual">
                                        <span class="badge bg-secondary"> Manual </span>
                                    </ng-container>
                                </ng-container>
                            </ng-template>
                        </td>

                        <!-- Type -->
                        <td>
                            <span [ngClass]="EnumNames.getSubscriptionTypeClass(subscription.type)">
                                {{ EnumNames.getSubscriptionType(subscription.type) }}
                            </span>
                        </td>

                        <!-- Client -->
                        <td>
                            <ng-container *ngIf="subscription.clientRef">
                                <a [ngClass]="{ 'fst-italic text-black-50': !subscription.denormClientFirstName }" [routerLink]="['/', AngularRoutes.client, subscription.clientRef.id]" class="">
                                    {{ subscription.denormClientFirstName || 'Undefined' }} {{ subscription.denormClientLastName }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="subscription.type == SubscriptionType.manual">
                                {{ subscription.manualClientName }}
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="subscription.merchantRef">
                                <a [ngClass]="{ 'fst-italic text-black-50': !subscription.denormMerchantCompanyName }" [routerLink]="['/', AngularRoutes.MERCHANT, subscription.merchantRef.id]">
                                    {{ subscription.denormMerchantCompanyName || 'Undefined' }}
                                </a>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="subscription.parkingRef">
                                <a [ngClass]="{ 'fst-italic text-black-50': !subscription.denormParkingTitle }" [routerLink]="['/', AngularRoutes.parking, subscription.parkingRef.id]">
                                    {{ subscription.denormParkingTitle || 'Undefined' | slice: 0:30 }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="subscription.type == SubscriptionType.manual">
                                {{ subscription.manualParkingName }}
                            </ng-container>
                        </td>
                        <td>
                            <span [ngClass]="{ 'bg-success': subscription.baysBooked != 1 }" class="badge bg-secondary"> {{ subscription.baysBooked }} bays </span>
                        </td>

                        <td>
                            {{ subscription.startDate | customDate: 'D MMM YY' }}
                        </td>
                        <td>
                            {{ subscription.endDate | customDate: 'D MMM YY' }}
                        </td>
                        <td>
                            <span [ngClass]="{ 'bg-success': subscription.period != 1 }" class="badge bg-secondary"> {{ subscription.period }} {{ subscription.termNamePlural }} </span>
                        </td>
                        <!--                <td>-->
                        <!--                    <ng-container *ngIf="subscription.plan?.feeInCents">-->

                        <!--                        R{{subscription.periodFee | formatCents }}-->
                        <!--                    </ng-container>-->
                        <!--                </td>-->
                        <td>
                            <!--                    {{subscription.baysBooked}} bay * {{subscription.period}} {{subscription.termNamePlural}} =-->
                            R{{ subscription.periodFee | formatCents }}
                            <!--                    <ng-container *ngIf="subscription.plan.feeInCents">-->
                            <!--                        R{{subscription.period * subscription.plan.feeInCents * subscription.baysBooked | formatCents }}-->
                            <!--                    </ng-container>-->
                        </td>
                        <!--                <td>-->
                        <!--                    <ng-container *ngIf="subscription.plan.feeInCents"> R{{ subscription.totalContractPayoutValue | formatCents }}</ng-container>-->
                        <!--                </td>-->
                        <td>
                            <span *ngIf="subscription.nextDebitDate && subscription.nextDebitDate.toDate().getTime() < today; else normalDate">
                                <span class="badge bg-success text-dark">
                                    {{ subscription.nextDebitDate | formatDate }}
                                </span>
                            </span>
                            <ng-template #normalDate>
                                {{ subscription.nextDebitDate | formatDate }}
                            </ng-template>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
