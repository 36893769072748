<admin-app-header></admin-app-header>

<div *ngIf="invoices; else loading">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <h2 class="float-start d-inline-block">Invoices</h2>
            </div>
            <div class="col-md-2 d-flex align-items-center">
                <a [routerLink]="['/admin/invoices/create']" class="btn btn-sm btn-primary d-inline-block py-1 ms-3 mt-1">Create Invoice</a>
            </div>
            <div class="col-md-2 d-flex align-items-center">
                <select class="form-select form-select-sm" (change)="changeStatus($event)" [(ngModel)]="status">
                    <!-- <option value="">Filter Invoices</option> -->
                    <option *ngFor="let status of invoiceStates" [value]="status">{{ status | titlecase }}</option>
                </select>
            </div>
            <div class="col-md-2 ms-auto">
                <button (click)="globalService.exportcsv('all-invoices')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>
            </div>
        </div>

        <div class="content col-lg-12">
            <table class="table table-striped table-hover table-sm" id="all-invoices">
                <thead>
                    <tr>
                        <th scope="col">Created<br />Date</th>
                        <th scope="col">Payment<br />Method</th>
                        <th scope="col">Due<br />Date</th>
                        <th scope="col">Invoice<br />Status</th>
                        <th scope="col">Payout<br />Status</th>
                        <th scope="col">Invoice</th>
                        <th scope="col">Portfolio</th>
                        <th scope="col">Parking</th>
                        <th scope="col">Client</th>
                        <th scope="col">Bays</th>
                        <th scope="col">Total</th>
                        <!--                    <th scope="col">Attempts</th>-->
                        <th scope="col">Reference</th>
                        <th scope="col">Subscription</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let invoice of invoices">
                        <tr (contextmenu)="showContextMenu($event, invoice)" [ngClass]="{ selected: selectedInvoice === invoice }">
                            <td>
                                <a [routerLink]="['/client', invoice.clientRef.id, AngularRoutes.INVOICE, invoice.$key]">
                                    {{ invoice.createdAt | formatDate }}
                                </a>
                            </td>
                            <td>
                                <span *ngIf="invoice.$subscription?.paymentMethodRef" class="badge bg-blue"> PayGate </span>
                                <span *ngIf="!invoice.$subscription?.paymentMethodRef" class="badge bg-secondary"> Manual </span>
                            </td>
                            <td>
                                {{ invoice.dueDate | formatDate }}
                            </td>

                            <td>
                                <span [ngClass]="invoice.getStatusClass()" class="badge"> {{ invoice.status }} </span>&nbsp;

                                <span *ngIf="invoice.paygateAttempts" class="badge bg-danger"> Fail: {{ invoice.paygateAttempts }} </span>
                            </td>
                            <td>
                                <ng-container *ngIf="!invoice.payoutRef">
                                    <span *ngIf="!invoice.paidOut" class="badge bg-warning"> Not Paid Out </span>
                                </ng-container>

                                <ng-container *ngIf="invoice.payoutRef">
                                    <span [routerLink]="['/admin', 'payout', invoice.payoutRef?.id]" class="badge bg-success">
                                        {{ invoice.payoutRef?.id | slice: 0:5 }}
                                    </span>
                                </ng-container>
                            </td>
                            <td>
                                <a [routerLink]="['/', AngularRoutes.client, invoice.clientRef.id, AngularRoutes.INVOICE, invoice.$key]" class="text-decoration-underline">
                                    INV-{{ invoice.invoiceNumber }}
                                </a>
                            </td>

                            <td>
                                <a [routerLink]="['/', AngularRoutes.MERCHANT, invoice.merchantRef?.id]" class="text-decoration-underline">
                                    {{ invoice.denormMerchantCompanyName }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/', AngularRoutes.parking, invoice.parkingRef?.id]" class="text-decoration-underline">
                                    {{ invoice.denormParkingTitle | truncate: [50] }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/', AngularRoutes.client, invoice.clientRef.id, 'invoices']" class="text-decoration-underline">
                                    {{ invoice.denormClientFirstName }} {{ invoice.denormClientLastName }}
                                </a>
                            </td>
                            <td>
                                {{ invoice.baysBooked }}
                            </td>
                            <td>
                                {{ invoice.amountTotalDue | formatRands }}
                            </td>

                            <td>
                                {{ invoice.reference }}
                            </td>
                            <td>
                                <a [routerLink]="['/admin', 'subscription', invoice.subscriptionRef?.id]" class="text-decoration-underline">
                                    {{ invoice.subscriptionRef?.id | slice: 0:5 }}
                                </a>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <ng-container *ngIf="showLoadMoreButton">
                <a (click)="loadMore()" class="btn btn-sm btn-primary"> Load next 200 results </a>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #loading>
    <p>Loading invoices...</p>
</ng-template>
