import { Injectable } from '@angular/core'
import {
    addDoc,
    collection,
    doc,
    updateDoc,
    Firestore,
    getDocs,
    query,
    where,
    DocumentData,
    QueryDocumentSnapshot,
    orderBy,
    limit,
    startAfter,
    serverTimestamp,
    DocumentReference,
} from '@angular/fire/firestore'
import { from, map, Observable } from 'rxjs'
import { AdminChangeLog, ChangeLogsEntityEnum, FirestoreStructure, InvoiceStatus } from '@parkupp/core'
import * as firestore from 'firebase/firestore'

@Injectable({
    providedIn: 'root',
})
export class AdminChangeLogService {
    collection = collection(this.firestore, FirestoreStructure.ADMIN_CHANGE_LOGS)

    constructor(public firestore: Firestore) {}

    getDocRef(changeLogKey: string) {
        return doc(this.firestore, `${FirestoreStructure.ADMIN_CHANGE_LOGS}/${changeLogKey}`)
    }

    async addOrUpdate(changeLog: AdminChangeLog): Promise<{ status: string; reference?: string }> {
        if (!changeLog.createdAt) {
            changeLog.createdAt = serverTimestamp() as firestore.Timestamp
        }

        if (changeLog.$key) {
            const changeLogRef = doc(this.collection, changeLog.$key)
            await updateDoc(changeLogRef, changeLog.parse())
            return { status: 'success' }
        } else {
            const docRef = await addDoc(this.collection, changeLog.parse())
            return { status: 'success', reference: docRef.id }
        }
    }

    async list(type: ChangeLogsEntityEnum, params: { invoiceRef?: DocumentReference }): Promise<AdminChangeLog[]> {
        let changeLogsQuery = query(this.collection, where('updatedEntity', '==', type), orderBy('createdAt', 'desc'))

        if (type === ChangeLogsEntityEnum.INVOICE && params.invoiceRef) {
            changeLogsQuery = query(changeLogsQuery, where('invoiceRef', '==', params.invoiceRef))
        }

        const snapshot = await getDocs(changeLogsQuery)
        return snapshot.docs.map((doc) => new AdminChangeLog({ ...doc.data(), $key: doc.id }))
    }

    async getChangeLogsByClientRef(clientRef: string): Promise<AdminChangeLog[]> {
        const changeLogsQuery = query(this.collection, where('clientRef', '==', clientRef))
        const snapshot = await getDocs(changeLogsQuery)
        return snapshot.docs.map((doc) => new AdminChangeLog({ ...doc.data(), $key: doc.id }))
    }
}
