import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Client, CreateMerchantRequest, Merchant, MerchantMember, merchantMembersPath } from '@parkupp/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { MerchantService } from '../../../services/merchant.service'
import { AuthenticationService } from '../../../services/authentication.service'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { collection, collectionSnapshots } from '@angular/fire/firestore'
import { ClientService } from '../../../services/client.service'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'app-merchant-profile-form',
    templateUrl: './merchant-profile-form.component.html',
    styleUrls: ['./merchant-profile-form.component.scss'],
})
export class MerchantProfileFormComponent implements OnInit {
    @Input() dashboardView: boolean = false
    @Input() merchantKey: string
    @Input() showHeading = true
    @Input() isAdminCreateMerchant = false
    @Output() onCreatedMerchant = new EventEmitter<void>()
    merchant: Merchant

    formGroup: FormGroup
    loading = false
    error: string | null

    separateDialCode = false
    SearchCountryField = SearchCountryField
    CountryISO = CountryISO
    PhoneNumberFormat = PhoneNumberFormat
    preferredCountries: CountryISO[] = [CountryISO.SouthAfrica]

    modalRef?: BsModalRef
    completed: boolean
    imageType: string = 'merchants'
    valid: boolean = true

    membersSubscription: Subscription
    clientMemberMap: { [id: string]: Client } = {}
    merchantMembers: MerchantMember[]
    checkClaimsCount = 0

    constructor(
        private authService: AuthenticationService,
        private formBuilder: FormBuilder,
        private modalService: BsModalService,
        private merchantService: MerchantService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private clientService: ClientService,
        private toastrService: ToastrService,
        public authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        console.log(this.isAdminCreateMerchant)
        if (this.isAdminCreateMerchant) {
            this.formGroup = this.formBuilder.group({
                companyName: ['', [Validators.required]],
                companyRegistration: [''],
                vatNumber: [''],
                email: ['', [Validators.required]],
                // phone: ['', [Validators.required]],
                bookuppEnabled: [''],
                commissionPercentage: [''],
            })
        } else {
            this.formGroup = this.formBuilder.group({
                companyName: ['', [Validators.required]],
                companyRegistration: [''],
                vatNumber: [''],
                commissionPercentage: [''],
                // phone: ['', [Validators.required]],
            })
        }
        if (!this.isAdminCreateMerchant) {
            if (this.merchantKey) {
                this.merchantService.subscribeToMerchant(this.merchantKey).subscribe((document) => {
                    this.merchant = new Merchant(document)
                    if (this.merchant && this.merchant.companyName) {
                        this.merchant.$key = this.merchantKey
                        this.formGroup.patchValue({
                            companyName: this.merchant.companyName,
                            companyRegistration: this.merchant.companyRegistration,
                            vatNumber: this.merchant.vatNumber,
                            commissionPercentage: this.merchant.commissionPercentage,
                            // phone: this.merchant.phone,
                        })
                    }
                })
            } else {
                this.merchant = new Merchant()
            }

            this.getMerchantMembersList(this.merchantKey)
        }
    }

    getMerchantMembersList(uid: string) {
        if (this.membersSubscription) {
            this.membersSubscription.unsubscribe()
        }
        this.membersSubscription = collectionSnapshots(collection(this.merchantService.firestore, merchantMembersPath(uid))).subscribe((documents) => {
            let merchantMembers: MerchantMember[] = []
            documents.forEach((documentData) => {
                let merchantMember: MerchantMember = documentData.data() as MerchantMember
                merchantMember.$key = documentData.id
                merchantMembers.push(merchantMember)
                if (merchantMember.uid && !this.clientMemberMap[merchantMember.uid]) {
                    this.getClient(merchantMember.uid)
                }
            })
            this.merchantMembers = merchantMembers
        })
    }

    getClient(uid: string) {
        const clientSubscription = this.clientService.subscribeToClient(uid).subscribe((document) => {
            clientSubscription.unsubscribe()
            this.clientMemberMap[uid] = document as Client
        })
    }

    checkMerchantMap() {
        this.authService
            .setMerchantMap()
            .then(() => {
                if (this.authService.merchantMemberRoles[this.merchantKey]) {
                    this.loading = false
                    this.onCreatedMerchant.emit()
                    this.router.navigate([`merchant/${this.merchantKey}`])
                } else if (this.checkClaimsCount < 10) {
                    this.checkClaimsCount++
                    setTimeout(() => {
                        this.checkMerchantMap()
                    }, 500)
                } else {
                    this.error = 'Failed to create merchant profile. Please contact support.'
                    this.loading = false
                }
            })
            .catch((error) => {
                this.error = error['message']
                this.loading = false
            })
    }

    save() {
        if (this.formGroup.valid) {
            this.loading = true

            if (!this.isAdminCreateMerchant) {
                this.merchant.companyName = this.formGroup.value.companyName
                this.merchant.companyRegistration = this.formGroup.value.companyRegistration ? this.formGroup.value.companyRegistration : null
                this.merchant.vatNumber = this.formGroup.value.vatNumber ? this.formGroup.value.vatNumber : null
                this.merchant.commissionPercentage = this.formGroup.value.commissionPercentage
                // this.merchant.phone = this.formGroup.value.phone

                this.loading = true
                console.log(this.merchant.$key)
                if (this.merchant.$key) {
                    this.merchantService
                        .updateMerchant(
                            {
                                companyName: this.merchant.companyName,
                                companyRegistration: this.merchant.companyRegistration,
                                vatNumber: this.merchant.vatNumber,
                                commissionPercentage: this.merchant.commissionPercentage,
                                // phone: this.merchant.phone,
                            },
                            this.merchant.$key
                        )
                        .then(() => {
                            this.loading = false
                            this.onCreatedMerchant.emit()
                            this.router.navigate([`merchant/${this.merchantKey}`])
                        })
                        .catch((error) => {
                            this.error = error['message']
                            this.loading = false
                        })
                } else {
                    this.authService.user?.getIdToken().then((token) => {
                        const createMerchantRequest = new CreateMerchantRequest()
                        createMerchantRequest.merchant = this.merchant
                        this.merchantService.createMerchant(createMerchantRequest, token).subscribe((response) => {
                            this.loading = false
                            if (response.error) {
                                this.error = response.error
                            } else if (response.successful) {
                                this.merchantKey = response.merchantKey
                                this.checkClaimsCount = 0
                                this.checkMerchantMap()
                            }
                        })
                    })
                }
            } else {
                this.merchant = new Merchant()
                this.merchant.companyName = this.formGroup.value.companyName
                this.merchant.companyRegistration = this.formGroup.value.companyRegistration ? this.formGroup.value.companyRegistration : null
                this.merchant.vatNumber = this.formGroup.value.vatNumber ? this.formGroup.value.vatNumber : null
                this.merchant.commissionPercentage = this.formGroup.value.commissionPercentage

                // this.merchant.phone = this.formGroup.value.phone
                this.merchant.bookuppEnabled = this.formGroup.value.bookuppEnabled

                const createMerchantRequest = new CreateMerchantRequest()
                createMerchantRequest.merchant = this.merchant
                createMerchantRequest.email = this.formGroup.value.email
                createMerchantRequest.createdByAdmin = true
                this.authService.user?.getIdToken().then((token) => {
                    this.merchantService.createMerchant(createMerchantRequest, token).subscribe((response) => {
                        this.loading = false
                        if (response.error) {
                            this.error = response.error
                        } else if (response.successful) {
                            this.merchantKey = response.merchantKey
                            this.toastrService.success(`Invite sent to ${this.formGroup.value.email}`, 'Successful')
                            this.onCreatedMerchant.emit()
                            this.router.navigate([`merchant/${this.merchantKey}`])
                        }
                    })
                })
            }
        } else {
            this.valid = false
            Object.keys(this.formGroup.controls).forEach((field) => {
                const control = this.formGroup.controls[field]
                if (!control.valid) {
                    control.markAsTouched()
                    control.markAsDirty()
                }
            })
            this.formGroup.disable()
            this.formGroup.enable()
        }
    }
}
