import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { InvoiceStatus, Parking, parkingsPath, ParkingStatus } from '@parkupp/core'
import { collection, collectionData, doc, orderBy, query, updateDoc } from '@angular/fire/firestore'
import { GlobalService } from 'src/app/services/global.service'
import { environment } from 'src/environments/environment'

@Component({
    selector: 'app-admin-list-parkings-page',
    templateUrl: './admin-list-parkings-page.component.html',
    styleUrls: ['./admin-list-parkings-page.component.scss'],
})
export class AdminListParkingsPageComponent implements OnInit {
    parkings: Parking[]
    parkingStatus = ParkingStatus
    selectedParking: Parking

    constructor(private adminSvc: AdminService, private globalService: GlobalService) {
        const ref = query(collection(this.adminSvc.firestore, parkingsPath()), orderBy('updatedAt', 'desc'))
        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.parkings = []
            documents.forEach((documentData) => {
                let parking = new Parking(documentData)
                parking.$key = documentData.documentId

                // if (parking.merchantRef) {
                //     docData(documentData.merchantRef, { idField: 'documentKey' }).subscribe((data) => {
                //         parking.$merchant = data as Merchant
                //         parking.$merchant.$key = data['documentKey']
                //     })
                // }

                if (parking.parkingStatus != ParkingStatus.DISABLED) {
                    this.parkings.push(parking)
                }
            })
        })
    }

    ngOnInit(): void {}

    approveParking(key: any) {
        return new Promise<any>((resolve) => {
            const reference = doc(this.adminSvc.firestore, parkingsPath(), key)
            updateDoc(reference, {
                parkingStatus: ParkingStatus.PUBLISHED,
            })
        })
    }

    async showContextMenu(event: MouseEvent, parking: Parking) {
        this.globalService.contextMenuItems = []
        this.selectedParking = parking
        this.globalService.showContextMenu(event)
        this.globalService.contextMenuItems.push({
            icon: 'fa fa-link',
            label: 'Copy PayFast Special Link',
            action: () => this.copyPayFastSpecialLink(),
        })
    }

    copyPayFastSpecialLink() {
        const url = `${environment.host}/parking/${this.selectedParking.$key}?allowPayfast=true`
        navigator.clipboard
            .writeText(url)
            .then(() => {
                console.log('URL copied to clipboard:', url)
            })
            .catch((err) => {
                console.error('Failed to copy URL to clipboard:', err)
            })
    }
}
