<admin-app-header></admin-app-header>
<div class="container-fluid">
    <div class="row">
        <div class="col-3">
            <h1 class="float-start">1. Invoice Payouts</h1>
            <div aria-label="Basic radio toggle button group" class="btn-group btn-group-sm m-2" role="group"></div>
        </div>
        <div class="col-3">
            <div class="form-check form-switch">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="showMonthlyPayouts"
                    [(ngModel)]="includeMonthlyMerchants"
                    (change)="applyFilters()"
                />
                <label class="form-check-label" for="showMonthlyPayouts">Include Monthly Merchants</label>
            </div>
            <div class="form-check form-switch">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="showBlankBankDetails"
                    [(ngModel)]="includeBlankBankDetails"
                    (change)="applyFilters()"
                />
                <label class="form-check-label" for="showBlankBankDetails">Include Blank Bank Details</label>
            </div>
        </div>
        <div class="col-3">
            <div>
                <div>Merchants Selected: {{ getMerchantsSelected() }}</div>
                <div>Invoices Selected: {{ selectedInvoices.length }}</div>
                <div>Total Amount: {{ getTotalAmount() | currency: 'ZAR' }}</div>
            </div>
        </div>
        <div class="col-3">
            <button (click)="createPayouts()" class="btn btn-primary btn-sm float-end m-3" type="button" [disabled]="busyCreatingPayouts">
                <span *ngIf="busyCreatingPayouts">
                    <i class="fas fa-spinner fa-spin"></i>
                </span>
                Create Payout Record/s
            </button>
            <!--    <button (click)="globalService.exportcsv('invoices-payouts')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to Excel</button>-->
        </div>
    </div>
    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm" id="invoices-payouts">
            <thead>
                <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Frequency</th>
                    <!-- <th scope="col">Created<br />Date</th> -->
                    <th scope="col">Due<br />Date</th>
                    <th scope="col">Payment<br />Date</th>
                    <th scope="col">Total</th>
                    <th scope="col">Payout</th>
                    <th scope="col">Fee</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Parking</th>
                    <th scope="col">Client</th>
                    <th scope="col">Invoice</th>
                    <th scope="col">Bank Details</th>
                    <th scope="col">Bank (OLD)</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let invoice of filteredInvoices">
                    <ng-container *ngIf="!invoice.payoutRef">
                        <tr
                            (mousedown)="toggleSelected(invoice)"
                            (mouseenter)="onHoverWithMouseDown($event, invoice)"
                            [class.selected]="selectedInvoices.includes(invoice)"
                        >
                            <td>
                                <ng-container *ngIf="invoice.paidOut">
                                    <span class="badge bg-success text-dark">Paid</span>
                                </ng-container>
                                <ng-container *ngIf="!invoice.paidOut">
                                    <span class="badge bg-danger text-light">Not Paid</span>
                                </ng-container>
                            </td>
                            <td>
                                <span
                                    [routerLink]="['/merchant/', invoice.$merchant?.$key, 'profile', 'payout']"
                                    class="badge"
                                    [ngClass]="{
                                        ' bg-success': invoice.$merchant?.payoutDetails?.payoutFrequency === PayoutFrequencyEnum.IMMEDIATE,
                                        ' bg-warning': invoice.$merchant?.payoutDetails?.payoutFrequency === PayoutFrequencyEnum.MONTHLY
                                    }"
                                >
                                    {{ invoice.$merchant?.payoutDetails?.payoutFrequency }}
                                </span>
                            </td>
                            <!-- <td>
                            <a [routerLink]="['/merchant/', invoice.$merchant?.$key, 'invoice', invoice.$key]">
                                {{ invoice.createdAt | formatDate }}
                            </a>
                        </td> -->
                            <td>
                                {{ invoice.dueDate | formatDate }}
                            </td>
                            <td>
                                {{ invoice.paymentDate | formatDate }}
                            </td>
                            <td>
                                {{ invoice.amountTotalDue | formatRands }}
                            </td>
                            <td>
                                <!-- FIXME: Undo this exception -->
                                {{ invoice.periodFinalPayout | formatRands }}
                            </td>
                            <td>
                                <span *ngIf="invoice.$merchant?.commissionPercentage === 0" class="badge bg-danger">
                                    {{ invoice.$merchant?.commissionPercentage }}%
                                </span>
                                <span *ngIf="invoice.$merchant?.commissionPercentage" class="badge bg-warning">
                                    {{ invoice.$merchant?.commissionPercentage }}%
                                </span>
                                <span
                                    *ngIf="!invoice.$merchant?.commissionPercentage && invoice.$merchant?.commissionPercentage !== 0"
                                    class="badge bg-secondary"
                                >
                                    15%
                                </span>
                            </td>

                            <td>
                                <a
                                    [routerLink]="['/merchant/', invoice.$merchant?.$key]"
                                    [title]="invoice.$merchant?.companyName"
                                    class="text-decoration-underline"
                                >
                                    {{ invoice.$merchant?.companyName | slice: 0:15 }}
                                </a>
                            </td>
                            <td>
                                <a
                                    [routerLink]="['/parking/', invoice.$parking?.$key]"
                                    [title]="invoice.$parking?.title"
                                    class="text-decoration-underline"
                                >
                                    {{ invoice.denormParkingTitle | slice: 0:15 }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/client/', invoice.clientRef.id, 'invoices']" class="text-decoration-underline">
                                    {{ invoice.denormClientFirstName || '' }}
                                    {{ invoice.denormClientLastName || '' }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/merchant/', invoice.$merchant?.$key, 'invoice', invoice.$key]" class="text-decoration-underline">
                                    INV-{{ invoice.invoiceNumber }}</a
                                >
                            </td>
                            <td>
                                <ng-container *ngIf="invoice.$merchant?.payoutDetails">
                                    <ng-container *ngIf="invoice.$merchant?.payoutDetails?.beneficiaryName">
                                        <span class="badge bg-success text-dark">{{ invoice.$merchant?.payoutDetails?.beneficiaryName }}</span>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="!invoice.$merchant?.payoutDetails?.beneficiaryName && invoice.$merchant?.payoutDetails?.accountNumber"
                                    >
                                        <span class="badge bg-warning text-dark"
                                            >{{ invoice.$merchant?.payoutDetails?.bank }}: {{ invoice.$merchant?.payoutDetails?.accountNumber }}
                                        </span>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!invoice.$merchant?.payoutDetails">
                                    <span
                                        class="badge bg-danger text-light"
                                        [routerLink]="['/merchant', invoice.$merchant?.$key, 'profile', 'payout']"
                                        >No Bank Details</span
                                    >
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="invoice.$parking?.$bankDetails?.accountHolderName">
                                    <a [routerLink]="['/merchant/', invoice.$merchant?.$key, 'profile', 'payout-details-old']" class="badge bg-info">
                                        OLD: {{ invoice.$parking?.$bankDetails?.accountNumber }}
                                    </a>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
