import { Component, OnInit } from '@angular/core'
import { register } from 'swiper/element/bundle'

register()

@Component({
    selector: 'app-testimonials-block',
    templateUrl: './testimonials-block.component.html',
    styleUrls: ['./testimonials-block.component.scss'],
})
export class TestimonialsBlockComponent implements OnInit {
    testimonials: any[] = [
        {
            name: 'Nabeel Nazir',
            review:
                'Great service for the peace of your mind\n' +
                'Just park your car and be relaxed 😌\n' +
                'One of best parking provider company\n' +
                'And also amazing prices as compared to the others specifically in Cape Town.',
            imageUrl: '/assets/images/testimonials/nabeel.png',
            stars: [1, 2, 3, 4, 5], // Represents a 5-star review
        },
        {
            name: 'Claire Millward',
            review: "Highly recommend this forward thinking and easy to use platform - this is perfect if you are out of town for a few months and need somehwere to leave your car. The team are always on hand to assist, and the Parking Manager at Canal Walk helped me personality on the days I had to drop my car off and collect it once again. Couldn't recommend more :)\n",
            imageUrl: '/assets/images/testimonials/claire.png',
            stars: [1, 2, 3, 4, 5],
        },
        {
            name: 'Trond Tornes',
            review: 'Thanks for the great service and effort less onboarding process! Recommendable!\n',
            imageUrl: '/assets/images/testimonials/trond.png',
            stars: [1, 2, 3, 4, 5],
        },
        {
            name: 'Theo Van Wyk',
            review: 'Fantastic service and team - super helpful!',
            imageUrl: '/assets/images/home/testimonial-2.png',
            stars: [1, 2, 3, 4, 5],
        },
        {
            name: 'Zara Poorun',
            review: 'Highly recommend this for any daily work travellers! Much more cost efficient than paying parking tolls per hour, allowing you to secure safe parking in the city. The process was smooth and efficient, with the ParkUpp team being extremely willing to help whenever!',
            imageUrl: '/assets/images/home/testimonial-1.png',
            stars: [1, 2, 3, 4, 5], // Represents a 5-star review
        },
        {
            name: 'Nick Taylor',
            review: 'Positive: Professionalism, Responsiveness. Super easy experience, works incredibly well. Employees attentive. Very pleased with the service.',
            imageUrl: '/assets/images/home/testimonial-3.png',
            stars: [1, 2, 3, 4, 5],
        },
        {
            name: 'Peter Sebola',
            review: 'Very friendly staff and provide adequate support. I would recommend it to anyone looking for parking space.',
            imageUrl: '/assets/images/home/testimonial-4.png',
            stars: [1, 2, 3, 4, 5],
        },
    ]

    constructor() {}

    ngOnInit(): void {
        const swiperEl = document.querySelector('.swiper-container-testimonials') as any
        if (swiperEl) {
            const swiperParams = {
                slidesPerView: 1,

                breakpoints: {
                    640: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                },
                mousewheel: {
                    forceToAxis: true,
                },
                // navigation: true,
                pagination: true,

                // injectStylesUrls: ['assets/swiper.css'],
            }

            Object.assign(swiperEl, swiperParams)

            // and now initialize it
            swiperEl.initialize()
        }
    }
}
